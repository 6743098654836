import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  switchMap,
  tap,
} from 'rxjs';
import { CustomerService } from 'src/app/blockModules/services/customers.service';
import { getSafeIsoDateString } from 'src/app/sharedModules/utils/dates';
import { ReportsService } from 'src/app/featureModules/services/reports.service';
import { GenerateReportModel } from 'src/app/sharedModules/models/generate-report.model';

@Component({
  selector: 'app-generate-report',
  templateUrl: './generate-report.component.html',
  styleUrls: ['./generate-report.component.scss'],
})
export class GenerateReportsComponent implements OnInit {
  isLoading: boolean;
  loadMessage: string = 'Please wait..';
  reportGenerateFormGroup: FormGroup;

  typeList = [
    { key: 'All', value: 'All' },
    { key: 'ACH', value: 'ACH' },
    { key: 'CREDITDEBIT', value: 'Credit/Debit' },
    // { key: 'INSTANTFUND', value: 'Instant Fund' }, // hiding the INF feature
    { key: 'ECHECK', value: 'E-Check' },
  ];

  processorList = [
    { key: 'All', value: 'All' },
    { key: 'BaseCommerce', value: 'Base Commerce' },
    { key: 'FirstNational', value: 'First National' },
  ];

  processorListAch = [
    { key: 'All', value: 'All' },
    { key: 'BaseCommerce', value: 'Base Commerce' },
    { key: 'FirstNational', value: 'First National' },
  ];

  processorListCreditDebit = [
    { key: 'All', value: 'All' },
    { key: 'NMIPaymentProcessor', value: 'NMI Payment Processor' },
  ];

  processorListInstantFund = [
    { key: 'All', value: 'All' },
    { key: 'Gallilio', value: 'Gallilio' },
  ];

  processorListEcheck = [
    { key: 'All', value: 'All' },
    { key: 'ECheck', value: 'ECheck' },
  ];

  statusList = [{ key: 'All', value: 'All' }];

  statusInstantAndECheck = [
    { key: 'All', value: 'All' },
    { key: 'pending', value: 'Pending' },
    { key: 'failed', value: 'Failed' },
    { key: 'successful', value: 'Successful' },
  ];

  statusCards = [
    { key: 'All', value: 'All' },
    { key: 'pending', value: 'Pending' },
    { key: 'failed', value: 'Failed' },
    { key: 'successful', value: 'Successful' },
  ];

  statusAch = [
    { key: 'All', value: 'All' },
    { key: 'pending', value: 'Pending' },
    { key: 'submitted', value: 'Submitted' },
    { key: 'processing', value: 'Processing' },    
    { key: 'successful', value: 'Successful' },
    { key: 'returned', value: 'Returned' },
    { key: 'rejected', value: 'Rejected' },
    { key: 'failed', value: 'Failed' },
    { key: 'cancelled', value: 'Cancelled' }
  ];

  sortDataList: any = [
    {
      key: { active: 'amount', direction: 'asc' },
      value: 'Ascending Amount',
    },
    {
      key: { active: 'amount', direction: 'desc' },
      value: 'Descending Amount',
    },
  ];

  sortDataAll = [
    {
      key: { active: 'amount', direction: 'asc' },
      value: 'Ascending Amount',
    },
    {
      key: { active: 'amount', direction: 'desc' },
      value: 'Descending Amount',
    },
    {
      key: { active: 'transactionId', direction: 'asc' },
      value: 'Ascending Tracking Id',
    },
    {
      key: { active: 'transactionId', direction: 'desc' },
      value: 'Descending Tracking Id',
    },
  ];

  sortDataAch = [
    {
      key: { active: 'amount', direction: 'asc' },
      value: 'Ascending Amount',
    },
    {
      key: { active: 'amount', direction: 'desc' },
      value: 'Descending Amount',
    },
    {
      key: { active: 'TrackingId', direction: 'asc' },
      value: 'Ascending Tracking Id',
    },
    {
      key: { active: 'TrackingId', direction: 'desc' },
      value: 'Descending Tracking Id',
    },
  ];

  sortDataEcheck = [
    {
      key: { active: 'amount', direction: 'asc' },
      value: 'Ascending Amount',
    },
    {
      key: { active: 'amount', direction: 'desc' },
      value: 'Descending Amount',
    },
    {
      key: { active: 'checkNumber', direction: 'asc' },
      value: 'Ascending Check Number',
    },
    {
      key: { active: 'checkNumber', direction: 'desc' },
      value: 'Descending Check Number',
    },
  ];

  sortDataCommon = [
    {
      key: { active: 'amount', direction: 'asc' },
      value: 'Ascending Amount',
    },
    {
      key: { active: 'amount', direction: 'desc' },
      value: 'Descending Amount',
    },
    {
      key: { active: 'TrackingId', direction: 'asc' },
      value: 'Ascending Tracking Id',
    },
    {
      key: { active: 'TrackingId', direction: 'desc' },
      value: 'Descending Tracking Id',
    },
    {
      key: { active: 'checkNumber', direction: 'asc' },
      value: 'Ascending Check Number',
    },
    {
      key: { active: 'checkNumber', direction: 'desc' },
      value: 'Descending Check Number',
    },
  ];

  showDateTypeList = [{ key: 'All', value: 'All' }];

  showDateTypeListCredit: any = [
    { key: 'All', value: 'All' },
    { key: 'EffectiveOn', value: 'Effective On' },
    { key: 'SentOn', value: 'Sent On' },
    { key: 'CompletedOn', value: 'Completed On' },
  ];

  showDateTypeListAll: any = [
    { key: 'All', value: 'All' },
    { key: 'CreatedOn', value: 'Created On' },
    { key: 'EffectiveOn', value: 'Effective On' },
    { key: 'SentOn', value: 'Sent On' },
    { key: 'CompletedOn', value: 'Completed On' },
  ];

  panelOpenState: boolean = false;
  minLengthTerm = 3;
  filteredCustomer: any[] = [];
  isShowNoFound: boolean = false;
  alphaNumericRegex = '^[a-zA-Z0-9]*$';
  decimalRegex = '^\\d+\\.\\d{2}$';
  numericRegex = '^[0-9]+$';
  noSpaceRegex = '^\\S.+\\S$';

  achReportList: any = [
    {
      check: false,
      heading: 'Tracking Id',
      actualName: 'trackingId',
      type: 'textbox',
      pattern: this.alphaNumericRegex,
      filter: {
        label: '',
        value: '',
      },
    },
    {
      check: false,
      heading: 'Customer Type',
      actualName: 'customerType',
      type: 'radio',
      value: 'All',
      filter: [
        { label: 'All' },
        { label: 'Company' },
        { label: 'Individuals' },
      ],
    },
    {
      check: false,
      heading: 'Merchant Id',
      actualName: 'merchantId',
      type: 'textbox',
      pattern: this.alphaNumericRegex,
      filter: {
        label: '',
        value: '',
      },
    },
    {
      check: false,
      heading: 'Account Type',
      actualName: 'accountType',
      type: 'radio',
      value: 'All',
      filter: [{ label: 'All' }, { label: 'Saving' }, { label: 'Checking' }],
    },
    {
      check: false,
      heading: 'Payment Type',
      actualName: 'paymentType',
      type: 'radio',
      value: 'All',
      filter: [{ label: 'All' }, { label: 'Credit' }, { label: 'Debit' }],
    },
    {
      check: false,
      heading: 'Entry Code',
      actualName: 'entryCode',
      type: 'radio',
      value: 'All',
      filter: [{ label: 'All' }, { label: 'PPD' }, { label: 'CCD' }],
    },
    {
      check: false,
      heading: 'Amount',
      type: 'input',
      pattern: this.decimalRegex,
      filter: {
        minimum: { actualName: 'fromAmount', label: 'From', value: '0.00' },
        maximun: { actualName: 'toAmount', label: 'To', value: '0.00' },
      },
    },
    {
      check: false,
      heading: 'Entered By',
      actualName: 'enteredBy',
      type: 'textbox',
      pattern: this.noSpaceRegex,
      filter: {
        label: '',
        value: '',
      },
    },
    {
      check: false,
      heading: 'Return Status Code',
      actualName: 'returnStatusCode',
      type: 'textbox',
      pattern: this.alphaNumericRegex,
      filter: {
        label: '',
        value: '',
      },
    },
    {
      check: false,
      heading: 'Recurring',
      type: 'None',
      filter: [{ label: 'Recurring', value: '' }],
    },
    {
      check: false,
      heading: 'NOC Status Code',
      actualName: 'nocStatusCode',
      type: 'textbox',
      pattern: this.alphaNumericRegex,
      filter: {
        label: '',
        value: '',
      },
    },
  ];

  cardReportList: any = [
    {
      check: false,
      heading: 'Tracking Id',
      actualName: 'transactionId',
      type: 'textbox',
      pattern: this.alphaNumericRegex,
      filter: {
        label: '',
        value: '',
      },
    },
    {
      check: false,
      heading: 'Customer Type',
      actualName: 'customerType',
      type: 'radio',
      value: 'All',
      filter: [
        { label: 'All' },
        { label: 'Company' },
        { label: 'Individuals' },
      ],
    },
    {
      check: false,
      heading: 'Amount',
      type: 'input',
      pattern: this.decimalRegex,
      filter: {
        minimum: { actualName: 'fromAmount', label: 'From', value: '0.00' },
        maximun: { actualName: 'toAmount', label: 'To', value: '0.00' },
      },
    },
    {
      check: false,
      heading: 'Entered By',
      actualName: 'enteredBy',
      type: 'textbox',
      pattern: this.noSpaceRegex,
      filter: {
        label: '',
        value: '',
      },
    },
    {
      check: false,
      heading: 'Recurring',
      type: 'None',
      filter: [{ label: 'Recurring', value: '' }],
    },
  ];

  instantFundReportList: any = [];

  echeckReportList: any = [
    {
      check: false,
      heading: 'Check Number',
      actualName: 'checkNumber',
      type: 'textbox',
      pattern: this.alphaNumericRegex,
      filter: {
        label: '',
        value: '',
      },
    },
    {
      check: false,
      heading: 'Customer Type',
      actualName: 'customerType',
      type: 'radio',
      value: 'All',
      filter: [
        { label: 'All' },
        { label: 'Company' },
        { label: 'Individuals' },
      ],
    },
    {
      check: false,
      heading: 'Amount',
      type: 'input',
      pattern: this.decimalRegex,
      filter: {
        minimum: { actualName: 'fromAmount', label: 'From', value: '0.00' },
        maximun: { actualName: 'toAmount', label: 'To', value: '0.00' },
      },
    },
    {
      check: false,
      heading: 'Entered By',
      actualName: 'enteredBy',
      type: 'textbox',
      pattern: this.noSpaceRegex,
      filter: {
        label: '',
        value: '',
      },
    }
  ];

  allReportList: any = [
    {
      check: false,
      heading: 'Tracking Id',
      actualName: 'trackingId',
      type: 'textbox',
      pattern: this.alphaNumericRegex,
      filter: {
        label: '',
        value: '',
      },
    },
    {
      check: false,
      heading: 'Merchant Id',
      actualName: 'merchantId',
      type: 'textbox',
      pattern: this.alphaNumericRegex,
      filter: {
        label: '',
        value: '',
      },
    },
    {
      check: false,
      heading: 'Customer Type',
      actualName: 'customerType',
      type: 'radio',
      value: 'All',
      filter: [
        { label: 'All' },
        { label: 'Company' },
        { label: 'Individuals' },
      ],
    },
    {
      check: false,
      heading: 'Account Type',
      actualName: 'accountType',
      type: 'radio',
      value: 'All',
      filter: [{ label: 'All' }, { label: 'Saving' }, { label: 'Checking' }],
    },
    {
      check: false,
      heading: 'Payment Type',
      actualName: 'paymentType',
      type: 'radio',
      value: 'All',
      filter: [{ label: 'All' }, { label: 'Credit' }, { label: 'Debit' }],
    },
    {
      check: false,
      heading: 'Check Number',
      actualName: 'checkNumber',
      type: 'textbox',
      pattern: this.alphaNumericRegex,
      filter: {
        label: '',
        value: '',
      },
    },
    {
      check: false,
      heading: 'Entry Code',
      actualName: 'entryCode',
      type: 'radio',
      value: 'All',
      filter: [{ label: 'All' }, { label: 'PPD' }, { label: 'CCD' }],
    },
    {
      check: false,
      heading: 'Amount',
      type: 'input',
      pattern: this.decimalRegex,
      filter: {
        minimum: { actualName: 'fromAmount', label: 'From', value: '0.00' },
        maximun: { actualName: 'toAmount', label: 'To', value: '0.00' },
      },
    },
    {
      check: false,
      heading: 'Entered By',
      actualName: 'enteredBy',
      type: 'textbox',
      pattern: this.noSpaceRegex,
      filter: {
        label: '',
        value: '',
      },
    },
    {
      check: false,
      heading: 'Return Status Code',
      actualName: 'returnStatusCode',
      type: 'textbox',
      pattern: this.alphaNumericRegex,
      filter: {
        label: '',
        value: '',
      },
    },
    {
      check: false,
      heading: 'Recurring',
      type: 'None',
      filter: [{ label: 'Recurring', value: '' }],
    },
  ];

  reportRecurringList: any = [
    {
      check: false,
      heading: 'Pay Frequency',
      actualName: 'payFrequency',
      type: 'radio',
      value: 'All',
      filter: [
        { label: 'All' },
        { label: 'Weekly' },
        { label: 'Biweekly' },
        { label: 'Monthly' },
        { label: 'Yearly' },
      ],
    },
    {
      check: false,
      heading: 'Installments',
      actualName: 'installments',
      type: 'textbox',
      pattern: this.numericRegex,
      filter: {
        label: 'Installments',
        value: '',
      },
    },
  ];

  minDate = new Date();
  maxDate = new Date();
  withNocFlag :boolean =false;

  constructor(
    private formBuilder: RxFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _toastrService: ToastrService,
    private _customerService: CustomerService,
    public _reportService: ReportsService
  ) {
    this.minDate.setFullYear(this.minDate.getFullYear() - 2);
    this.maxDate.setFullYear(this.maxDate.getFullYear() + 1);
  }

  ngOnInit(): void {
    this.isShowNoFound = false;
    this.activatedRoute.paramMap.subscribe((params) => { });
    if (!this._reportService.generateReportModel) {
      // new model
      this._reportService.generateReportModel = new GenerateReportModel();
      this._reportService.generateReportModel.typeOfService =
        this.typeList[0].key;
      // this._reportService.generateReportModel.processor =
      //   this.processorList[0].key;
      this.generateReportMoreFilter(
        this._reportService.generateReportModel.typeOfService
      );

      this._reportService.generateReportModel.status = this.statusList[0].key;

      this._reportService.generateReportModel.sortData =
        this.sortDataList[0].key;
      this._reportService.generateReportModel.showDateType =
        this.showDateTypeList[0].key;

      let startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 3);
      this._reportService.generateReportModel.startDate = startDate;
      this._reportService.generateReportModel.endDate = new Date();
    } else {
      // setting previous value
      let locModel = JSON.parse(
        JSON.stringify(this._reportService.generateReportModel)
      );
      locModel.startDate = new Date(locModel.startDate);
      locModel.endDate = new Date(locModel.endDate);

      locModel.status = !locModel.status
        ? this.statusList[0].key
        : locModel.status;

      locModel.showDateType = !locModel.showDateType
        ? this.showDateTypeList[0].key
        : locModel.showDateType;

      this.generateReportMoreFilter(
        this._reportService.generateReportModel.typeOfService
      );
      let selectedValue = this.sortDataList.filter(
        (obj) =>
          obj.key.active == locModel.sortData.active &&
          obj.key.direction == locModel.sortData.direction
      );
      locModel.sortData = selectedValue[0]?.key;

      this._reportService.generateReportModel = new GenerateReportModel();
      for (let obj in locModel) {
        if (locModel[obj])
          this._reportService.generateReportModel[obj] = locModel[obj];
      }
    }

    this.reportGenerateFormGroup = this.formBuilder.formGroup(
      this._reportService.generateReportModel
    );

    this.reportGenerateFormGroup.controls['customerName'].valueChanges
      .pipe(
        filter((res) => {
          this.isShowNoFound = false;
          if (res) {
            return (
              this.reportGenerateFormGroup.controls['customerName'].valid &&
              res !== null &&
              res.length >= this.minLengthTerm
            );
          } else {
            this.filteredCustomer = [];
          }
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.isShowNoFound = false;
          this.filteredCustomer = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this._customerService.SearchCustomers(value, true).pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
        )
      )
      .subscribe((data: any) => {
        if (data.data && !data.data.length) {
          this.filteredCustomer = [];
          this.isShowNoFound = true;
        } else {
          this.filteredCustomer = data.data;
          this.isShowNoFound = false;
        }
      });

    this.selectionChangeSortData();
  }

  clearSelection() {
    this.reportGenerateFormGroup.get('customerName').setValue(null);
    this.reportGenerateFormGroup.get('customerId').setValue(null);
    this.filteredCustomer = [];
  }

  onSelected() {
    var selected = (this.filteredCustomer = this.filteredCustomer.filter(
      (customer: any) =>
        this.reportGenerateFormGroup.get('customerName').value ==
        customer.firstName?.trim() + ' ' + customer.lastName?.trim()
    ));
    if (selected && selected[0])
      this.reportGenerateFormGroup
        .get('customerId')
        .setValue(selected[0].customerId);
  }

  selectedCustomerName(customer: any) {
    let lastName  = customer.lastName?.trim() == undefined ? '' : customer.lastName?.trim()
    return customer.firstName?.trim() + ' ' + lastName;
  }

  generateReport() {
    let isValidAllFields = this.reportGenerateFormGroup.valid;
    this._reportService.generateReportModel =
      this.reportGenerateFormGroup.value;

    this._reportService.generateReportModel.startDate = new Date(
      this._reportService.generateReportModel.startDate
    );
    this._reportService.generateReportModel.endDate = new Date(
      this._reportService.generateReportModel.endDate
    );
    this._reportService.generateReportModel.startDate?.setHours(12, 0, 0);
    this._reportService.generateReportModel.endDate?.setHours(12, 0, 0);
    this._reportService.generateReportModel.startDate = getSafeIsoDateString(
      this._reportService.generateReportModel.startDate
    );
    this._reportService.generateReportModel.endDate = getSafeIsoDateString(
      this._reportService.generateReportModel.endDate
    );

    if (
      this._reportService.generateReportModel.status == this.statusList[0].key
    ) {
      this._reportService.generateReportModel.status = null;
    }
    if (
      this._reportService.generateReportModel.showDateType ==
      this.showDateTypeList[0].key
    ) {
      this._reportService.generateReportModel.showDateType = null;
    }

    this.settingDefaultValue();

    this._reportService.moreFilterList.forEach((report: any) => {
      if (report.check && isValidAllFields) {
        switch (report.heading) {
          case 'Recurring':
            this._reportService.generateReportModel.isRecurring = report.check;
            break;
          case 'Tracking Id':
            isValidAllFields = new RegExp(report.pattern).test(
              report.filter.value
            );
            if (isValidAllFields) {
              this._reportService.generateReportModel.trackingId =
                report.filter.value;
            }
            break;
          case 'Merchant Id':
            isValidAllFields = new RegExp(report.pattern).test(
              report.filter.value
            );
            if (isValidAllFields) {
              this._reportService.generateReportModel.merchantId =
                report.filter.value;
            }
            break;
          case 'Account Type':
            if (report.value != 'All')
              this._reportService.generateReportModel.accountType =
                report.value;
            break;
          case 'Payment Type':
            if (report.value != 'All')
              this._reportService.generateReportModel.paymentType =
                report.value;
            break;
          case 'Entry Code':
            if (report.value != 'All')
              this._reportService.generateReportModel.entryCode = report.value;
            break;
          case 'Check Number':
            isValidAllFields = new RegExp(report.pattern).test(
              report.filter.value
            );
            if (isValidAllFields) {
              this._reportService.generateReportModel.checkNumber =
                report.filter.value;
            }
            break;
          case 'Customer Type':
            if (report.value == 'Company')
              this._reportService.generateReportModel.customerType = true;
            if (report.value == 'Individuals')
              this._reportService.generateReportModel.customerType = false;
            break;
          case 'Amount':
            isValidAllFields = new RegExp(report.pattern).test(
              report.filter.minimum.value
            );
            if (isValidAllFields) {
              this._reportService.generateReportModel.fromAmount = +report
                .filter.minimum.value
                ? +report.filter.minimum.value
                : 0;

              //max value set
              isValidAllFields = new RegExp(report.pattern).test(
                report.filter.maximun.value
              );
              if (isValidAllFields) {
                this._reportService.generateReportModel.toAmount = +report
                  .filter.maximun.value
                  ? +report.filter.maximun.value
                  : 0;
              }
            }

            break;
          case 'Entered By':
            isValidAllFields = new RegExp(report.pattern).test(
              report.filter.value
            );
            if (isValidAllFields) {
              this._reportService.generateReportModel.enteredBy =
                report.filter.value;
            }
            break;
          case 'Pay Frequency':
            if (report.value != 'All')
              this._reportService.generateReportModel.payFrequency =
                report.value;
            break;
          case 'Installments':
            isValidAllFields = new RegExp(report.pattern).test(
              report.filter.value
            );
            if (isValidAllFields) {
              this._reportService.generateReportModel.installments = +report
                .filter.value
                ? +report.filter.value
                : 0;
            }
            break;
          case 'Return Status Code':
            isValidAllFields = new RegExp(report.pattern).test(
              report.filter.value
            );
            if (isValidAllFields) {
              this._reportService.generateReportModel.returnStatusCode =
                report.filter.value;
            }
            break;
            case 'NOC Status Code':
            isValidAllFields = new RegExp(report.pattern).test(
              report.filter.value
            );
            if (isValidAllFields) {
              this._reportService.generateReportModel.nocStatusCode =
                report.filter.value;
            }
            break;
        }
      }
    });
    if (isValidAllFields) {
      this.router.navigate(['reports/generate-report-data']);
    } else {
      this._toastrService.error('Please fill all the required details!');
    }
  }

  settingDefaultValue() {
    if (this._reportService.generateReportModel) {
      this._reportService.generateReportModel.isRecurring = false;
      this._reportService.generateReportModel.trackingId = null;
      this._reportService.generateReportModel.merchantId = null;
      this._reportService.generateReportModel.accountType = null;
      this._reportService.generateReportModel.paymentType = null;
      this._reportService.generateReportModel.entryCode = null;
      this._reportService.generateReportModel.checkNumber = null;
      this._reportService.generateReportModel.customerType = null;
      this._reportService.generateReportModel.fromAmount = null;
      this._reportService.generateReportModel.toAmount = null;
      this._reportService.generateReportModel.enteredBy = null;
      this._reportService.generateReportModel.payFrequency = null;
      this._reportService.generateReportModel.installments = 0;
      this._reportService.generateReportModel.returnStatusCode = null;
    }
  }

  navigateToBackPage() {
    this.router.navigate(['reports']);
  }

  generateReportMoreFilter(typeOfService: string) {
    switch (typeOfService) {
      case 'All':
        this.showDateTypeList = this.showDateTypeListAll;
        this.sortDataList = this.sortDataCommon;
        this.statusList = this.statusAch;

        if (!this._reportService.moreFilterList)
          this._reportService.moreFilterList = this.allReportList;
        break;
      case 'ACH':
        this.showDateTypeList = this.showDateTypeListAll;
        this.sortDataList = this.sortDataAch;
        this.statusList = this.statusAch;

        if (!this._reportService.moreFilterList)
          this._reportService.moreFilterList = this.achReportList;
        break;
      case 'CREDITDEBIT':
        // for Show datetype dropdown list
        this.showDateTypeList = this.showDateTypeListCredit;
        this.sortDataList = this.sortDataAll;
        this.statusList = this.statusCards;

        if (!this._reportService.moreFilterList)
          this._reportService.moreFilterList = this.cardReportList;
        break;
      case 'INSTANTFUND':
        this.showDateTypeList = this.showDateTypeListAll;
        this.sortDataList = this.sortDataAll;
        this.statusList = this.statusInstantAndECheck;
        //todo
        if (!this._reportService.moreFilterList)
          this._reportService.moreFilterList = this.instantFundReportList;
        break;
      case 'ECHECK':
        this.showDateTypeList = this.showDateTypeListAll;
        this.sortDataList = this.sortDataEcheck;
        this.statusList = this.statusInstantAndECheck;

        if (!this._reportService.moreFilterList)
          this._reportService.moreFilterList = this.echeckReportList;
        break;
    }
  }

  selectionMoreFilter() {
    switch (this.reportGenerateFormGroup.get('typeOfService').value) {
      case 'All':
        this.showDateTypeList = this.showDateTypeListAll;
        this.sortDataList = this.sortDataCommon;
        this.statusList = this.statusAch;
        this._reportService.moreFilterList = this.allReportList;
        break;
      case 'ACH':
        this.showDateTypeList = this.showDateTypeListAll;
        this.sortDataList = this.sortDataAch;
        this.statusList = this.statusAch;
        this._reportService.moreFilterList = this.achReportList;
        break;
      case 'CREDITDEBIT':
        // for Show datetype dropdown list
        this.showDateTypeList = this.showDateTypeListCredit;
        this.sortDataList = this.sortDataAll;
        this.statusList = this.statusCards;

        this._reportService.moreFilterList = this.cardReportList;
        break;
      case 'INSTANTFUND':
        this.showDateTypeList = this.showDateTypeListAll;
        this.sortDataList = this.sortDataAll;
        this.statusList = this.statusInstantAndECheck;

        this._reportService.moreFilterList = this.instantFundReportList;
        break;
      case 'ECHECK':
        this.showDateTypeList = this.showDateTypeListAll;
        this.sortDataList = this.sortDataEcheck;
        this.statusList = this.statusInstantAndECheck;

        this._reportService.moreFilterList = this.echeckReportList;
        break;
    }
    this.reportGenerateFormGroup
      .get('sortData')
      .setValue(this.sortDataList[0].key);
  }

  changeCheckBox(report: any) {
    switch (this.reportGenerateFormGroup.get('typeOfService').value) {
      case 'All':
        if (report.heading == 'Recurring') {
          if (report.check)
            this._reportService.moreFilterList = [
              ...this._reportService.moreFilterList,
              ...this.reportRecurringList,
            ];
          else {
            this._reportService.moreFilterList = this.allReportList;
          }
        }
        break;
      case 'ACH':
        if (report.heading == 'Recurring') {
          if (report.check)
            this._reportService.moreFilterList = [
              ...this._reportService.moreFilterList,
              ...this.reportRecurringList,
            ];
          else {
            this._reportService.moreFilterList = this.achReportList;
          }
        }
        break;
      case 'CREDITDEBIT':
        if (report.heading == 'Recurring') {
          if (report.check)
            this._reportService.moreFilterList = [
              ...this._reportService.moreFilterList,
              ...this.reportRecurringList,
            ];
          else {
            this._reportService.moreFilterList = this.cardReportList;
          }
        }

        break;
      case 'INSTANTFUND':
        //todo
        break;
      case 'ECHECK':
        if (report.heading == 'Recurring') {
          if (report.check)
            this._reportService.moreFilterList = [
              ...this._reportService.moreFilterList,
              ...this.reportRecurringList,
            ];
          else {
            this._reportService.moreFilterList = this.echeckReportList;
          }
        }

        break;
    }
  }

  selectionChangeSortData() {
    this.reportGenerateFormGroup.controls['sortBy'].setValue(
      this.reportGenerateFormGroup.controls['sortData'].value.active
    );
    this.reportGenerateFormGroup.controls['sortDirection'].setValue(
      this.reportGenerateFormGroup.controls['sortData'].value.direction
    );
  }

  onBlurInput(report: any) {
    if (report.filter && report.filter.value)
      report.filter.value = report.filter.value?.trim();
  }

  onBlurMinNumber(report: any) {
    if (+report.filter.minimum.value >= 0) {
      report.filter.minimum.value = report.filter.minimum.value
        ? (+report.filter.minimum.value).toFixed(2)
        : (0).toFixed(2);
      return report.filter.minimum.value;
    }
  }

  onStatusChanged(value:any){
    if(value.toLowerCase() =="all" || value.toLowerCase()=="successful" || value.toLowerCase() =="returned")
    {
    this.withNocFlag=false;
    }
    else{
      this.withNocFlag=true;
      this.reportGenerateFormGroup.controls['withNoc'].setValue(false);
    }
    
  }

  onBlurMaxNumber(report: any) {
    if (+report.filter.maximun.value >= 0) {
      report.filter.maximun.value = report.filter.maximun.value
        ? (+report.filter.maximun.value).toFixed(2)
        : (0).toFixed(2);
      return report.filter.maximun.value;
    }
  }
}
