import {
  Component,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import {
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { AddCustomerFromCustomergroupComponent } from '../../../customer-related/add-customer-from-customergroup/add-customer-from-customergroup.component';
import { BatchTransactionsService } from 'src/app/blockModules/services/batch-transactions.service';
import { UpdateBatchModel } from 'src/app/sharedModules/models/update-batch.model';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs';
import { CustomerService } from 'src/app/blockModules/services/customers.service';
import moment, { max } from 'moment';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-batch-transaction-details',
  templateUrl: './batch-transaction-details.component.html',
  styleUrls: ['./batch-transaction-details.component.scss'],
})
export class BatchTransactionDetailsComponent implements OnInit {
  isLoading: boolean = false;
  loadMessage: string = 'Please wait ....';
  groupName: string = '';
  transactionType: string = '';
  customerGrpscolumn: TableColumn[] = [
    { displayName: 'CUSTOMER', name: 'name', width: '15%' },
    { displayName: 'UNIQUE ID', name: 'uniqueId', width: '10%' },
    { displayName: 'ACCT #', name: 'accountNumber', width: '8%' },
    { displayName: 'TYPE', name: 'achTransactionType', width: '11%', className: 'row-height-transaction-type' },
    { displayName: 'AMOUNT', name: 'defaultAmount', width: '11%', className: 'row-height-transaction-type' },
    { displayName: 'STATUS', name: 'batchTransactionStatus', width: '10%' },
    { displayName: 'NOTES', name: 'notes', width: '10%' },
    { displayName: 'INCLUDE', name: 'isInclude', width: '10%' },
  ];
  data: any;
  batchBasicDetails: any = {};
  @Input()
  noRecordMessage: string;
  allColumns: TableColumn[];
  userRole: any;
  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };
  datatablemargin: string = '0px';
  batchId: string;
  companyId: string = '';
  companyName: string = '';
  selectedCustomerList: any;

  applyToAllForm: FormGroup;
  updateNoteForm: FormGroup;
  @ViewChild('applyToAllDialog') applyToAllDialog!: TemplateRef<any>;
  @ViewChild('addCustomerDialog') addCustomerDialog!: TemplateRef<any>;
  @ViewChild('viewNoteDialog') viewNoteDialog!: TemplateRef<any>;
  @ViewChild('batchDeclineDialog') batchDeclineDialog!: TemplateRef<any>;
  @ViewChild('memoText') memoText: ElementRef<HTMLInputElement>;
  filteredCustomer: any[] = [];
  isShowNoFound: boolean = false;
  customerSearchFormGroup: FormGroup;
  minLengthTerm = 3;
  isReadonlyType: boolean = true;
  isDisableButton: boolean = false;

  effectiveDate: Date = new Date();
  nextCutOffTime: Date = new Date();
  cutOffTime: any = {};
  sortBy: SortChanged = {
    active: 'entryDate',
    direction: 'desc',
  };

  updateBatchFormGroup: FormGroup;
  updateBatchModel: UpdateBatchModel;

  transactionTypesGrid = [
    { value: 'CREDIT', displayName: 'Credit' },
    { value: 'DEBIT', displayName: 'Debit' },
  ];

  minEffectiveDate = new Date();
  insertedCustomer: any;
  initiatedBy: string = '';
  reviewedBy: string = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _toastrService: ToastrService,
    private customerService: CustomerService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private batchTransactionsService: BatchTransactionsService,
    private formBuilder: RxFormBuilder
  ) {
    this.allColumns = this.customerGrpscolumn;

    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userProfile['role'];
  }

  transactionTypes = [
    { value: 'CREDIT', displayName: 'Credit Only' },
    { value: 'DEBIT', displayName: 'Debit Only' },
    { value: 'CREDIT & DEBIT', displayName: 'Credit & Debit' },
  ];

  isSaveButtonVisible: boolean;
  isSaveAndQueueButtonVisible: boolean;
  isApprovalButtonVisible: boolean;
  isRequestApprovalButtonVisible: boolean;

  ngOnInit(): void {

    this.activatedRoute.paramMap.subscribe((params) => {
      this.batchId = params.get('batchId');
    });

    this.updateBatchModel = new UpdateBatchModel();
    this.updateBatchFormGroup = this.formBuilder.formGroup(
      this.updateBatchModel
    );

    this.applyToAllForm = this.fb.group({
      transactionType: ['', Validators.required],
      defaultAmount: ['', Validators.required],
    });

    this.customerSearchFormGroup = this.fb.group({
      customerName: ['', Validators.required],
      customerId: [],
    });

    this.loadData();

    this.customerSearchFormGroup.controls['customerName'].valueChanges
      .pipe(
        filter((res) => {
          this.isShowNoFound = false;
          if (res) {
            return (
              this.customerSearchFormGroup.controls['customerName'].valid &&
              res !== null &&
              res.length >= this.minLengthTerm
            );
          } else {
            this.filteredCustomer = [];
          }
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.isShowNoFound = false;
          this.filteredCustomer = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.customerService.SearchCustomersForBatch(value, true).pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
        )
      )
      .subscribe((data: any) => {
        if (data.data && !data.data.length) {
          this.isShowNoFound = true;
          this.filteredCustomer = [];
        } else {
          this.isShowNoFound = false;
          this.filteredCustomer = data.data;
        }
      });

    this.companyId = localStorage.getItem('CompanyId') || '';
    this.companyName = localStorage.getItem('CompanyName') || '';

    this.updateNoteForm = this.fb.group({
      description: ['']
    });
  }

  navigateToBatchTransactions() {
    this.router.navigate(['batchTransactions']);
  }

  loadData() {
    if (this.batchId) {
      this.getAllCustomerByBatchId(this.batchId);
    }
  }

  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize ||
      this.groupName != ''
    ) {
      this.page = event;
      const startIndex = this.page.pageIndex * this.page.pageSize;
      const endIndex = startIndex + this.page.pageSize;
      this.data = this.selectedCustomerList.slice(startIndex, endIndex);
    }
  }

  openApplyToAllDialog() {
    this.customerSearchFormGroup.reset();
    this.applyToAllForm.reset();
    const dialogRef = this.dialog.open(this.applyToAllDialog, { disableClose: true });
  }

  onCancel() {
    this.customerSearchFormGroup.reset();
    this.applyToAllForm.reset();
    this.updateBatchFormGroup.reset();
    this.dialog.closeAll();
  }

  onApply() {
    if (this.applyToAllForm.valid) {
      const transactionType = this.applyToAllForm.get('transactionType')?.value;
      const defaultAmount = this.applyToAllForm.get('defaultAmount')?.value;
      if (
        this.transactionType.toLowerCase() === 'credit & debit' ||
        this.transactionType.toLocaleLowerCase() ===
        transactionType.toLowerCase()
      ) {
        this.selectedCustomerList.forEach((record) => {
          if (record.isInclude && record.accountNumber) {
            record.achTransactionType = transactionType;
            record.defaultAmount = defaultAmount;
          }
        });
        this.applyToAllForm.reset();
        this.dialog.closeAll();
      } else {
        this._toastrService.error(
          'Selected ACH transaction type does not align with the batch transaction type'
        );
      }
    }
  }

  openCustomerDialog() {
    this.dialog.open(this.addCustomerDialog, {
      height: '220px',
      width: '500px',
      disableClose: true
    });
  }

  openNewCustomerDialog(customerId: any = null) {
    const dialogRef = this.dialog.open(AddCustomerFromCustomergroupComponent, {
      height: '590px',
      width: '800px', disableClose: true,
      data: { customerId },
    });

    dialogRef.afterClosed().subscribe((customer: any) => {
      if (customer?.customerId) {
        if (!customer?.isActive) {
          this.dialog.closeAll();
          return false;
        }
        const newCustomer = {
          uniqueId: "",
          firstName: customer.firstName,
          lastName: customer.lastName,
          customerId: customer.customerId,
          accountNumber: customer.accountNumber,
          achTransactionType:
            this.transactionType.toLowerCase() === 'credit'
              ? 'CREDIT'
              : 'DEBIT',
          defaultAmount: 0.00,
          batchTransactionStatus: 'PENDING',
          notes: null,
          isInclude: !!customer.accountNumber,
        };

        this.data = [newCustomer, ...this.data];
        this.selectedCustomerList = this.data;
        this.isDisableButton = this.selectedCustomerList.length === 0;
        this.page.length = this.selectedCustomerList.length;
        this.dialog.closeAll();
      }
    });
  }

  clearSelection() {
    this.customerSearchFormGroup.get('customerName').setValue(null);
    this.customerSearchFormGroup.get('customerId').setValue(null);
    this.filteredCustomer = [];
  }

  onSelected(event: MatAutocompleteSelectedEvent): any {
    const selectedCustomerId = event.option.value;

    const selectedCustomer = this.filteredCustomer.find(
      (customer) => customer.customerId === selectedCustomerId
    );
    this.insertedCustomer = this.filteredCustomer;
    if (selectedCustomer) {
      this.customerSearchFormGroup.get('customerId')?.setValue(selectedCustomer.customerId);

    }
    if (selectedCustomer) {
      const fullName = `${selectedCustomer.firstName?.trim()} ${selectedCustomer.lastName?.trim()}`;
      this.customerSearchFormGroup.get('customerName').setValue(fullName);
    }
  }

  getAllCustomerByBatchId(batchId: any) {
    this.isLoading = true;
    this.batchTransactionsService
      .getAllCustomerByBatchId(batchId)
      .subscribe((response) => {
        this.isLoading = false;
        if (response.data != null) {
          this.groupName = response.data.customerGroupName;
          this.transactionType = response.data.groupTransactionType.toUpperCase();
          this.effectiveDate = response.data.effectiveDate;
          this.initiatedBy = response.data.initiatedBy;
          this.reviewedBy = response.data.reviewedBy;
          this.data = response.data.batchTransactionDetails;
          this.selectedCustomerList = this.data;
          this.isDisableButton = this.selectedCustomerList.length === 0;
          this.isSaveButtonVisible = response.data.batchStatus.toLowerCase() == 'new' && this.userRole != 1;
          this.isSaveAndQueueButtonVisible = response.data.batchStatus.toLowerCase() == 'new' && (this.userRole == 0 || this.userRole == 3);
          this.isApprovalButtonVisible = response.data.batchStatus.toLowerCase() == 'review' && (this.userRole == 0 || this.userRole == 3);
          this.isRequestApprovalButtonVisible = response.data.batchStatus.toLowerCase() == 'new' && this.userRole == 2;
          this.page.length = this.data.length;
          this.onPageChanged(this.page);
          this.updateBatchFormGroup.patchValue({
            batchId: this.batchId || '',
            batchName: response.data.batchName,
            effectiveDate: this.effectiveDate || new Date()
          });
          this.batchBasicDetails.effectiveDate = response.data.effectiveDate;
          this.batchBasicDetails.batchStatus = response.data.batchStatus;
          this.batchBasicDetails.fileSentDate = response.data.fileSentDate;
          this.getDateTimeWithZoneFormate(this.batchBasicDetails);
        } else {
          this.data = [];
          this._toastrService.error(response.errorMessage);
        }
      }),
      (error) => {
        this.data = [];
        this.isLoading = false;
        this._toastrService.error(error.errorMessage);
      };
  }

  get getTotalTransactions(): number {
    return this.selectedCustomerList?.filter((record) => record.isInclude).length || 0;
  }

  get getTotalDebits(): number {
    return this.selectedCustomerList?.filter(record => record.isInclude && record.achTransactionType.toLowerCase() === 'debit')
      .reduce((sum, record) => sum + (Number(record.defaultAmount) || 0), 0) || 0;
  }

  get getTotalCredits(): number {
    return this.selectedCustomerList?.filter(record => record.isInclude && record.achTransactionType.toLowerCase() === 'credit')
      .reduce((sum, record) => sum + (Number(record.defaultAmount) || 0), 0) || 0;
  }

  onSortChanged(event: SortChanged) {
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;

      if (this.data) {
        const { active, direction } = this.sortBy;

        const sortedList = [...this.data].sort((a, b) => {
          let valueA = a[active];
          let valueB = b[active];

          if (active === 'name') {
            valueA = `${a.firstName || ''} ${a.lastName || ''}`.trim();
            valueB = `${b.firstName || ''} ${b.lastName || ''}`.trim();
          }

          if (valueA === undefined || valueA === null || valueA === '')
            return direction === 'asc' ? -1 : 1;
          if (valueB === undefined || valueB === null || valueB === '')
            return direction === 'asc' ? 1 : -1;

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return direction === 'asc'
              ? valueA.localeCompare(valueB)
              : valueB.localeCompare(valueA);
          } else if (typeof valueA === 'number' && typeof valueB === 'number') {
            return direction === 'asc' ? valueA - valueB : valueB - valueA;
          } else {
            const strA = String(valueA);
            const strB = String(valueB);
            return direction === 'asc'
              ? strA.localeCompare(strB)
              : strB.localeCompare(strA);
          }
        });

        this.data = sortedList;
      }
    }
  }

  updateBatch(status: any) {
    if (status == "DECLINED") {
      this.updateBatchFormGroup.controls['effectiveDate']?.clearValidators();
      this.updateBatchFormGroup.controls['effectiveDate']?.updateValueAndValidity();
    }

    if (this.updateBatchFormGroup.valid) {
      this.updateBatchFormGroup.value.batchName = this.updateBatchFormGroup.value.batchName.trim()
      const isInValidDefaultAmount = this.selectedCustomerList?.filter(c => c.isInclude)?.some(customer => {
        const amount = Number(customer.defaultAmount);
        return isNaN(amount) || amount <= 0;
      });

      if (isInValidDefaultAmount) {
        this._toastrService.error("Amount must be greater than zero for all customers");
        return;
      }

      if (this.selectedCustomerList) {
        this.updateBatchModel.batchTransactionDetails = this.selectedCustomerList.map(
          (customer) => ({
            uniqueId: customer.uniqueId,
            customerId: customer.customerId,
            eventType: customer.achTransactionType,
            amount: customer.defaultAmount,
            notes: customer.notes,
            isInclude: customer.isInclude
          })
        );
      }

      this.updateBatchFormGroup.patchValue({
        batchStatus: status,
        memo: this.memoText != undefined ? this.memoText.nativeElement.value : '',
        batchTransactionDetails: this.updateBatchModel.batchTransactionDetails
      });

      this.updateBatchFormGroup.value.effectiveDate = new Date(this.updateBatchFormGroup.value.effectiveDate).toLocaleDateString();

      this.isLoading = true;
      this.batchTransactionsService
        .UpdateBatch(this.updateBatchFormGroup.value, this.batchId)
        .subscribe(
          (response) => {
            this.isLoading = false;
            if (response.data != null) {
              this._toastrService.success(response.data.message);
              this.navigateToBatchTransactions();
            } else {
              this._toastrService.error(response.errorMessage);
            }
          },
          (error) => {
            this.isLoading = false;
            this._toastrService.error(
              'Something went wrong, Please contact administrator!'
            );
          }
        );
      this.dialog.closeAll();
    } else {
      this.updateBatchFormGroup.markAllAsTouched();
    }
  }

  insertSelectedCustomer() {
    const selectedCutsomerId =
      this.customerSearchFormGroup.get('customerId').value;
    if (selectedCutsomerId) {
      const isCustomerAlreadyExits = this.data.some(
        (customer) => customer.customerId === selectedCutsomerId
      );
      if (isCustomerAlreadyExits) {
        this._toastrService.error('Selected customer already exists');
      } else {
        const selectedCustomer = this.insertedCustomer.find(
          (customer: any) => customer.customerId === selectedCutsomerId
        );

        const customer = {
          uniqueId: null,
          firstName: selectedCustomer.firstName,
          lastName: selectedCustomer.lastName,
          customerId: selectedCustomer.customerId,
          accountNumber: selectedCustomer.accountNumber,
          achTransactionType:
            this.transactionType.toLowerCase() === 'credit'
              ? 'CREDIT'
              : 'DEBIT',
          defaultAmount: 0.00,
          batchTransactionStatus: 'PENDING',
          notes: null,
          isInclude: !!selectedCustomer.accountNumber,
        };

        this.data = [customer, ...this.data];
        this.selectedCustomerList = this.data;
        this.isDisableButton = this.selectedCustomerList.length === 0;
        this.page.length = this.selectedCustomerList.length;
        this.dialog.closeAll();
      }
      this.clearSelection();
    }
    this.isLoading = false;
  }

  openViewNoteDialog(data: any) {
    let customerRecord = this.data?.find(record => record.customerId == data);
    if (customerRecord) {
      this.updateNoteForm = this.fb.group({
        description: [customerRecord.notes, [Validators.max(1000)]],
        customerId: [data]
      });

      this.dialog.open(this.viewNoteDialog, {
        height: '280px',
        width: '350px',
        disableClose: true
      });
    }
  }

  updateNote() {
    if (!this.updateNoteForm.invalid) {
      const customerId = this.updateNoteForm.get('customerId')?.value;
      const description = this.updateNoteForm.get('description')?.value;
      var foundIndex = this.data.findIndex(x => x.customerId == customerId);
      this.data[foundIndex].notes = description;
      this.updateNoteForm.reset();
      this.dialog.closeAll();
    }
  }

  changeEffectiveDate() {
    if (this.updateBatchModel.effectiveDate) {
      this.nextCutOffTime = this.updateBatchModel.effectiveDate;
    }
  }

  getDateTimeWithZoneFormate(batchDetails: any) {
    if (batchDetails == undefined && batchDetails?.batchStatus == undefined) {
      this.cutOffTime = { date: '', time: '' };
      return false;
    }
    const cutOffTime = batchDetails.fileSentDate != undefined && 
                      batchDetails.fileSentDate != null && 
                      batchDetails.batchStatus.toUpperCase() != "NEW" && 
                      batchDetails.batchStatus.toUpperCase() != "QUEUED" && 
                      batchDetails.batchStatus.toUpperCase() != "REVIEW" &&
                      batchDetails.batchStatus.toUpperCase() != "DECLINED" ? 
                      batchDetails.fileSentDate : 
                      batchDetails.effectiveDate;
    const momentTimezone = moment.tz.guess(); // Detect user's timezone
    const momentDate = moment
      .utc(cutOffTime instanceof Date ? cutOffTime : new Date(cutOffTime)) // Ensure the input is a Date
      .tz(momentTimezone); // Convert to the user's timezone

    // Format the date and time separately
    const formattedDate = momentDate.format('MM/DD/YYYY');
    const formattedTime = momentDate.format('hh:mm A');
    const todayDate = new Date();
    const currentMonth = String(todayDate.getMonth() + 1).padStart(2, '0');
    const currentDay = String(todayDate.getDate()).padStart(2, '0');
    const currentYear = todayDate.getFullYear();
    const formattedTodaysDate = `${currentMonth}/${currentDay}/${currentYear}`;
    // If the time is in the future, add 30 minutes
    if (formattedDate == formattedTodaysDate && (batchDetails.batchStatus.toUpperCase() == "NEW" || batchDetails.batchStatus.toUpperCase() == "QUEUED" || batchDetails.batchStatus.toUpperCase() == "REVIEW")) {
      // Get current UTC time
      const currentTimeUTC = moment.utc(todayDate);
      // Calculate the next rounded 30-minute mark
      const minutes = currentTimeUTC.minutes();
      const extraMinutes = (5 - (minutes % 5)) % 5; // Adds 5 minutes to the current time if needed
      const roundedTime = currentTimeUTC.add(extraMinutes, 'minutes');
      this.cutOffTime = {
        date: roundedTime.tz(momentTimezone).format('MM/DD/YYYY'),
        time: roundedTime.tz(momentTimezone).format('hh:mm A') + ' ' + moment.tz(momentTimezone).zoneAbbr(),
      };
      return false;
    }
    if (new Date(cutOffTime) > new Date()) {
      this.cutOffTime = {
        date: formattedDate,
        time: /*formattedTime + ' '*/ '12:00 AM ' + moment.tz(momentTimezone).zoneAbbr(),
      };
      return false;
    }
    this.cutOffTime = {
      date: formattedDate,
      time: formattedTime + ' ' + moment.tz(momentTimezone).zoneAbbr(),
    };
  }

  openBatchDeclineDialog() {
    this.dialog.open(this.batchDeclineDialog, {
      height: '260px',
      width: '480px',
      disableClose: true
    });
  }
}

