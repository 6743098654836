import {
  Component,
  ViewChild,
  AfterViewInit,
  Input,
  Output,
  OnDestroy,
  SimpleChanges,
  SimpleChange,
  OnChanges,
  OnInit,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TableColumn } from '../../models/data-table.model';
import { PageChanged, SortChanged } from '../../models/pagination.model';
import { SelectionModel } from '@angular/cdk/collections';
import { SendVia } from 'src/app/featureModules/enums/sendVia';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { InvoiceStatusConstant, TicketStatusConstant } from '../../constants/ticket-status-constant';
import * as moment from 'moment-timezone';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']  
})
export class DataTableComponent implements AfterViewInit, OnChanges, OnDestroy {
  dateControl = new FormControl(moment().toDate());
  columnsPrepared: TableColumn[];

  @Input()
  isViewInvoicePage = false;

  @Input()
  isSpacerRequired = false;

  @Input()
  allColumns: TableColumn[];

  @Input()
  selection = new SelectionModel<TableColumn>(true, []);

  @Input() highlightedRows: any[] = [];

  @Input()
  filterText: string;
  @Input()
  fromDate: Date;
  @Input()
  toDate: Date;
  @Input()
  pageSizeOptions = [5, 10, 25, 100];

  @Input()
  noRecordMessage: string;
  invoiceRecurring: boolean;
  invoiceOverdue: boolean;
  invoiceUnpaid: boolean;

  @Input()
  set columns(columns: TableColumn[]) {
    const tmpArray = columns.slice();
    if (this._actionDelete) {
      tmpArray.push({
        name: '__actions',
        displayName: '',
      });
    }

    if (this._actionRefresh) {
      tmpArray.push({
        name: '__actions',
        displayName: '',
      });
    }

    this.columnsPrepared = tmpArray;
  }

  @Input()
  data: JSON[];

  @Input()
  page: PageChanged;

  @Input()
  sortBy: SortChanged;

  @Input()
  isLoading: boolean;

  @Input()
  loadMessage: string;  

  @Input()
  isLoadingPaging: boolean;

  @Input()
  isCreditDebitSummary: boolean = false;

  @Input()
  isInstantBankAnalysis: boolean = false;

  @Input()
  isInventory: boolean = false;

  @Input()
  advanceFilterCount: number;

  @Input()
  searchboxpadding : string ="0px 16px 0px 16px";

  @Input()
  datatablemargin : string ="16px";

  @Input()
  role: any;

  @Input()
  isCategory: boolean = false;

  @Input()
  isTicket: boolean = false;

  @Input()
  isStatementHistorySummary: boolean = false;

  @Input()
  isBatch: boolean = false;

  @Input()
  isViewBatch: boolean = true;

  _actionDelete: boolean;

  @Input()
  set actionDelete(value: boolean) {
    this._actionDelete = value;
    if (value && this.columnsPrepared) {
      this.columnsPrepared.push({
        name: '__actions',
        displayName: '',
      });
    }
  }

  _actionRefresh: boolean;
  @Input()
  set actionRefresh(value: boolean) {
    this._actionRefresh = value;
    if (value && this.columnsPrepared) {
      this.columnsPrepared.push({
        name: '__actions',
        displayName: '',
      });
    }
  }

  @Input()
  deletedFilter = false;

  @Input()
  showMoreMenu = false;

  @Input()
  isSearch: boolean = true;

  @Input()
  isSearchDate: boolean = false;

  @Input()
  isSearchMonth: boolean = false;

  @Input()
  isExport: boolean = true;

  @Input()
  isDownloadButtonVisible: boolean = false;

  @Input()
  isScroll: boolean = false;

  @Input()
  isActive: boolean = true;

  @Input()
  isDelete: boolean = false;

  @Input()
  isFilter: boolean = true;

  @Input()
  isAddBtn: boolean = false;

  @Input()
  fxFlexCustom : string = "30%";

  @Input()
  isCreateNewBtn: boolean = false;

  @Input()
  isImportBtn: boolean = false;

  @Input()
  isTableHeading: boolean = false;

  @Input()
  isTableHeadingText: string = 'Table';

  @Input()
  isRefreshBanKTransaction: boolean = false;

  @Input()
  isRefreshbankBalance: boolean = false;

  @Input()
  isRefreshall: boolean = false;

  @Input()
  isTotalTransactionLbl: boolean = false;

  @Input()
  showPaginator: boolean = true;

  @Output()
  rowClickedEvent = new EventEmitter();

  @Output()
  actionRefreshEvent = new EventEmitter();

  @Output()
  actionDeleteEvent = new EventEmitter();

  @Output()
  pageChangedEvent = new EventEmitter();

  @Output()
  sortChangedEvent = new EventEmitter();

  @Output()
  onFilterEvent = new EventEmitter();

  @Output()
  advanceFilterEvent = new EventEmitter();

  @Output()
  deleteBasedOnSelectionEvent = new EventEmitter();

  @Output()
  customerEditEvent = new EventEmitter();

  @Output()
  customerGroupEditEvent = new EventEmitter();

  @Output()
  customerGroupDeleteEvent = new EventEmitter();

  @Output()
  onBatchTransactionDeleteEvent = new EventEmitter();

  @Output()
  onBatchTransactionCloneEvent = new EventEmitter();

  @Output()
  onBatchTransactionProcessNowEvent = new EventEmitter();

  @Output()
  customerGroupViewEvent = new EventEmitter();

  @Output()
  batchViewEvent = new EventEmitter();

  @Output()
  deleteCardRecurringTransactionEvent = new EventEmitter();

  @Output()
  deleteAchRecurringTransactionEvent = new EventEmitter();

  @Output()
  serviceEditEvent = new EventEmitter();

  @Output()
  serviceDeleteEvent = new EventEmitter();

  @Output()
  ticketWithdrawEvent = new EventEmitter();

  @Output()
  navigationToPageEvent = new EventEmitter();

  @Output()
  exportEvent = new EventEmitter();

  @Output()
  importEvent = new EventEmitter();

  @Output()
  ibvResendEvent = new EventEmitter();

  @Output()
  ibvViewEvent = new EventEmitter();

  @Output()
  refreshBankTransactionEvent = new EventEmitter();

  @Output()
  ticketClickedEvent = new EventEmitter();

  @Output()
  invoiceNumberClickedEvent = new EventEmitter();

  @Output()
  raiseTicketClickedEvent = new EventEmitter();

  @Output()
  productCountClickedEvent = new EventEmitter();

  @Output()
  withdrawClickedEvent = new EventEmitter();

  @Output()
  writeReviewClickedEvent = new EventEmitter();

  @Output()
  viewPDFEvent = new EventEmitter();

  @Output()
  downloadPDFEvent = new EventEmitter();

  @Output()
  viewInvoiceChangeEvent = new EventEmitter();

  @Output()
  paymentLinkViewEvent = new EventEmitter();

  @Output()
  paymentDuplicateLinkEvent = new EventEmitter();

  
  @Output()
  bankEditEvent = new EventEmitter();

  @Output()
  cardEditEvent = new EventEmitter();

  @Output()
  rowDoubleClickedEvent = new EventEmitter();

  searchTextPattern = '^[a-zA-Z0-9@.-_ ]*$';

  @Input()
  startDate: string;

  @Input()
  endDate: string;

  @Input()
  statementSelectedPeriod: string;
  @Input()
  transactionType : string;
  @Input()
  transactionTypes : any[];

  @Input()
  skipSearchTextPattern : boolean = false;

  @Output() 
  amountTextValidationEvent = new EventEmitter<boolean>();

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  
  currentDate = new Date();
  cutOffTimeNew = new  Date();
  
  constructor(private _router: Router) {  
    this.setDefaultMonth();
  }

  setDefaultMonth() {
    const now = moment();
    this.dateControl.setValue(now.toDate());  
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  formatData(data: { [key: string]: unknown }, column: TableColumn) {
    if (typeof column.converter === 'function') {
      return column.converter(data[column.name], data);
    } else if (column.name == 'sendVia') {
      return data[column.name] == SendVia[SendVia.BOTH]
        ? SendVia.SMS_Comma_EMAIL
        : data[column.name];
    }

    return data[column.name];
  }

  getColumnNames(columns: TableColumn[] = []) {
    return columns.map((column) => column.name);
  }

  rowClicked(event: any) {
    this.rowClickedEvent.emit(event);
  }

  deleteClicked(event: MouseEvent) {
    const e = event.currentTarget as HTMLElement;
    this.actionDeleteEvent.emit(e.id);
  }

  isRowHighlighted(row: any): boolean {
    return this.highlightedRows.includes(row);
  }

  refreshClicked(event: MouseEvent) {
    const e = event.currentTarget as HTMLElement;
    this.actionRefreshEvent.emit(e.id);
  }

  ngOnDestroy() {
    if (this.paginator) {
      this.paginator.page.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const data: SimpleChange = changes['data'];
    if (data) {
      if (data.currentValue !== data.previousValue) {
        this.dataSource = new MatTableDataSource(this.data);
      }
    }
  }

  ngAfterViewInit() {
    this.paginator?.page.subscribe(this.pageChangedEvent);
    this.sort.active = this.sortBy.active;
    this.sort.sortChange.subscribe(this.sortChangedEvent);
  }

  checkValidation(data: any) {
    const isInvalid = !/^\d+(\.\d{1,2})?$/.test(data.defaultAmtText);
    this.amountTextValidationEvent.emit(isInvalid);
  }
  
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: TableColumn): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'}`;
  }

  headerMenuClick(selected: string, displayName: string) {
    switch (selected) {
      case 'Unsort':
        this.sortChangedEvent.emit({ active: '', direction: 'asc' });
        break;
      case 'SortByCompany':
        this.sortChangedEvent.emit({ active: 'type', direction: 'desc' });
        break;
      case 'SortByIndividual':
        this.sortChangedEvent.emit({ active: 'type', direction: 'asc' });
        break;
      case 'SortByPending':
        this.sortChangedEvent.emit({ active: 'pending', direction: 'asc' });
        break;
      case 'SortBySuccess':
        this.sortChangedEvent.emit({ active: 'successful', direction: 'asc' });
        break;
      case 'Hide':
        var index = this.columnsPrepared.findIndex(
          (obj) => obj.displayName == displayName
        );
        if (index != -1) {
          this.columnsPrepared.splice(index, 1);
        }
        break;
      case 'ShowColumns':
        this.columnsPrepared = JSON.parse(JSON.stringify(this.allColumns));
        break;
      case 'Sms':
        this.onFilterEvent.emit(SendVia[SendVia.SMS]);
        break;
      case 'Email':
        this.onFilterEvent.emit(SendVia[SendVia.EMAIL]);
        break;
      case 'Both':
        this.onFilterEvent.emit(SendVia[SendVia.BOTH]);
        break;
      case 'All':
        this.onFilterEvent.emit('');
        break;
      case 'Delete':
        this.onBatchTransactionDeleteEvent.emit(displayName); //displayName as batchId
        break;
      case 'Clone':
        this.onBatchTransactionCloneEvent.emit(displayName); //displayName as batchId
        break;
      case 'ProcessNow':
        this.onBatchTransactionProcessNowEvent.emit(displayName); //displayName as fileTrackingId
        break;

    }
  }

  onEnter() {
    let isValidFilterText =this.skipSearchTextPattern?this.filterText: new RegExp(this.searchTextPattern).test(
      this.filterText
    );
    if (isValidFilterText) {
      if (
        this.isInstantBankAnalysis &&
        this.filterText.toLocaleLowerCase() ==
          SendVia.SMS_Comma_EMAIL.toLocaleLowerCase()
      ) {
        this.onFilterEvent.emit(SendVia[SendVia.BOTH]);
      } else {
        this.onFilterEvent.emit(this.filterText?.trim());
      }
    }
  }
  onEnterSearchDate() {    
    var data = {
      fromDate: this.fromDate,
      toDate: this.toDate?.toISOString(),
    };
    this.onFilterEvent.emit(data);
  }

  onMonthSelected(event: any, datepicker: any) {
    const selectedDate = event;
    const ctrlValue = this.dateControl.value ? moment(this.dateControl.value) : moment();
    ctrlValue.month(event.getMonth()).year(event.getFullYear());
    this.dateControl.setValue(ctrlValue.toDate());
    const firstDay = moment(selectedDate).startOf('month').toDate();
    const isCurrentMonth = moment(selectedDate).isSame(moment(), 'month');
    const lastDay = isCurrentMonth ? moment().toDate() : moment(selectedDate).endOf('month').toDate();
    datepicker.close();
    var data = {
      fromDate: firstDay,
      toDate: lastDay,
    };
    this.onFilterEvent.emit(data);
  }

  filterMonths = (date: Date | null): boolean => {
    const currentDate = new Date();
    const selectedDate = date || new Date();
    const isMonthInPast = 
      selectedDate.getFullYear() < currentDate.getFullYear() || 
      (selectedDate.getFullYear() === currentDate.getFullYear() && selectedDate.getMonth() <= currentDate.getMonth());

    return isMonthInPast;
  };

  onBlurSearch() {
    if (this.filterText) this.filterText = this.filterText?.trim();
  }

  onClearText() {
    this.filterText = '';
    this.onFilterEvent.emit(this.filterText);
  }
  onClearDate() {
    this.fromDate = null;
    this.toDate = null;
    var data = {
      fromDate: this.fromDate?.toISOString(),
      toDate: this.toDate?.toISOString(),
    };
    this.onFilterEvent.emit(data);
  }

  onAdvanceFilterClick() {
    this.advanceFilterEvent.emit();
  }

  onExportClick() {
    this.exportEvent.emit();
  }
  onImportClick() {
    this.importEvent.emit();
  }
  onDownloadClicked(){
    this.downloadPDFEvent.emit();
  }

  deleteBasedOnSelectionClick() {
    this.deleteBasedOnSelectionEvent.emit(!this.CheckingSelectedItem());
  }

  editCustomerClick(customerId: any) {
    this.customerEditEvent.emit(customerId);
  }

  editCustomerGroupClick(customerGroupId: any) {
    this.customerGroupEditEvent.emit(customerGroupId);
  }

  editBankClick(customerBankAccountId: any) {
    this.bankEditEvent.emit(customerBankAccountId);
  }

  editCardClick(customerCardId: any) {
    this.cardEditEvent.emit(customerCardId);
  }

  // deleteCustomerGroupClick(customerGroupId: any, isActive: boolean) {
  //   this.customerGroupDeleteEvent.emit({customerGroupId, isActive});
  // }

  viewCustomerGroupClick(customerGroupId: any) {
    this.customerGroupViewEvent.emit(customerGroupId);
  }
  viewBatchTransactionsClick(batchId: any) {
    this.batchViewEvent.emit(batchId);
  }

  deleteCardRecurringTransactionClick(trackingId: any) {
    this.deleteCardRecurringTransactionEvent.emit(trackingId);
  }
  deleteAchRecurringTransactionClick(trackingId: any) {
    this.deleteAchRecurringTransactionEvent.emit(trackingId);
  }
  deleteServiceClick(service: any) {
    this.serviceDeleteEvent.emit(service);
  }

  ticketWithdrawClick(ticket: any) {
    this.ticketWithdrawEvent.emit(ticket);
  }

  editServiceClick(serviceId: any) {
    this.serviceEditEvent.emit(serviceId);
  }

  paymentLinkViewClick(id: any) {
    this.paymentLinkViewEvent.emit(id);
  }

  paymentDuplicateLinkClick(id: any) {
    this.paymentDuplicateLinkEvent.emit(id);
  }

  linkClick(event: any, status: any) {
    if (status == 'Withdraw') {
      this.withdrawClickedEvent.emit(event);
    } else {
      this.writeReviewClickedEvent.emit(event);
    }
  }

  ticketClick(event: any) {
    this.ticketClickedEvent.emit(event);
  }

  raiseTicket(event :any , status:any){
    if (status == 'Raise A Ticket') {
      this.raiseTicketClickedEvent.emit(event);
    }
  }

  invoiceNumberClick(event: any) {    
    this.invoiceNumberClickedEvent.emit(event);
  }
  productCountClick(event: any) {
    this.productCountClickedEvent.emit(event);
  }

  navigatetoBillingProfile() {
    this._router.navigate(['billing/billing-profile']);
  }

  viewPDF(id: any) {
    this.viewPDFEvent.emit(id);
  }
  downloadPDF(id: any) {
    this.downloadPDFEvent.emit(id);
  }

  getNameInitials(firstName: string, lastName: string) {
    if (firstName && lastName) {
      return (firstName.charAt(0) + lastName.charAt(0)).trim().toUpperCase();
    } else if (firstName) {
      return (firstName.charAt(0) + firstName.charAt(1)).trim().toUpperCase();
    }
    return null;
  }

  navigateToPage() {
    this.navigationToPageEvent.emit();
  }

  ibvResendClick(customer: any) {
    this.ibvResendEvent.emit(customer);
  }

  ibvViewClick(customer: any) {
    this.ibvViewEvent.emit(customer);
  }

  refreshBankTransaction(type: string) {
    this.refreshBankTransactionEvent.emit(type);
  }

  CheckingSelectedItem() {
    let active = [];
    let inactive = [];
    if (this.selection && this.selection.selected) {
      this.selection.selected.forEach((element: any) => {
        if (element.isActive) {
          active.push(element.isActive);
        } else {
          inactive.push(element.isActive);
        }
      });
    }
    return active.length >= inactive.length;
  }

  getActionToolTip(status) {
    let toolTipMessage = '';

    switch (status.toLowerCase()) {
      case 'pending':
        toolTipMessage =
          'Transaction data pulling process for linked account(s) is in progress and will be completed shortly. Please check after few moments.';
        break;
      case 'failed':
        toolTipMessage =
          'It looks like transaction data pulling process for linked account(s) failed due to unexpected error. Please try with new IBA request.';
        break;
    }

    return toolTipMessage;
  }

  onInvoiceToggleChange(event: MatSlideToggleChange, type: string) {
    switch (type) {
      case 'unpaid':
        this.invoiceOverdue = false;
        this.invoiceRecurring = false;
        break;
      case 'overdue':
        this.invoiceUnpaid = false;
        this.invoiceRecurring = false;
        break;
      case 'recurring':
        this.invoiceOverdue = false;
        this.invoiceUnpaid = false;
        break;
    }
    this.viewInvoiceChangeEvent.emit({ value: event.checked, status: type });
  }
  getRating(data: any) {
    if (data > 0) {
      return data;
    }
    return '0';
  }

  getGivenStarCount(data: any) {
    if (data > 0) {
      return parseInt(data);
    }
    return 0;
  }

  getRemainStarCount(data: any) {
    if (data > 0) {
      return 5 - parseInt(data);
    }
    return 0;
  }

  getStatus(data: any) {
    if (
      data == TicketStatusConstant.InProcess ||
      data == TicketStatusConstant.Open ||
      data == TicketStatusConstant.Assigned ||
      data == TicketStatusConstant.Reopened
    ) {
      return 'Withdraw';
    } else if (
      data == TicketStatusConstant.Withdrawn ||
      data == TicketStatusConstant.InformationRequired ||
      data == InvoiceStatusConstant.Paid ||
      data.toUpperCase() == InvoiceStatusConstant.Pending ||
      data.toUpperCase() == InvoiceStatusConstant.Sent ||
      data.toUpperCase()==InvoiceStatusConstant.OnHold ||
      data.toUpperCase()==InvoiceStatusConstant.Completed||
      data.toUpperCase()==InvoiceStatusConstant.Successful
      
    ) {
      return '';
    } else if(data == InvoiceStatusConstant.Failed||data.toUpperCase()==InvoiceStatusConstant.Incomplete){
        return 'Raise A Ticket'
    }
    
    else {
      return 'Write a Review';
    }
    return 0;
  }

  cardNumberEncryption(cardNumber: any) {
    if (cardNumber) {
      cardNumber = '**** **** **** ' + cardNumber.slice(cardNumber.length - 4);
    }
    return cardNumber;
  }

  rowDoubleClicked(event: any) {
    this.rowDoubleClickedEvent.emit(event);
  }
  isIconClicked(cutOffTime: Date) {    
    this.cutOffTimeNew = cutOffTime;
    this.isBeforeCutoff();
  }  
  isBeforeCutoff(): boolean {    
    const cutOffTimeCheck = new Date(this.cutOffTimeNew); 
    return cutOffTimeCheck.getDate() >= this.currentDate.getDate();
  }
  
  getDateTimeWithZoneFormate(data : any): { date: string, time: string } {
    const cutOffTime = data.fileSentDate != undefined && 
                      data.fileSentDate != null && 
                      data.batchStatus.toUpperCase() != "NEW" && 
                      data.batchStatus.toUpperCase() != "QUEUED" && 
                      data.batchStatus.toUpperCase() != "REVIEW" &&
                      data.batchStatus.toUpperCase() != "DECLINED" ? 
                      data.fileSentDate : 
                      data.cutOffTime;
    const momentTimezone = moment.tz.guess(); // Detect user's timezone
    const momentDate = moment
      .utc(cutOffTime instanceof Date ? cutOffTime : new Date(cutOffTime)) // Ensure the input is a Date
      .tz(momentTimezone); // Convert to the user's timezone

    // Format the date and time separately
    const formattedDate = momentDate.format('MM/DD/YYYY');
    const formattedTime = momentDate.format('hh:mm A');
    const todayDate = new Date();
    const currentMonth = String(todayDate.getMonth() + 1).padStart(2, '0');
    const currentDay = String(todayDate.getDate()).padStart(2, '0');
    const currentYear = todayDate.getFullYear();
    const formattedTodaysDate = `${currentMonth}/${currentDay}/${currentYear}`;
    
    // If the time is in the future, add 30 minutes
    if ( formattedDate == formattedTodaysDate && (data.batchStatus.toUpperCase() == "NEW" || data.batchStatus.toUpperCase() == "QUEUED" || data.batchStatus.toUpperCase() == "REVIEW")) {
         // Get current UTC time
         const currentTimeUTC = moment.utc(todayDate);
         // Calculate the next rounded 30-minute mark
         const minutes = currentTimeUTC.minutes();
         const extraMinutes = (5 - (minutes % 5)) % 5; // Adds 5 minutes to the current time if needed
         const roundedTime = currentTimeUTC.add(extraMinutes, 'minutes');
           return {
             date: roundedTime.tz(momentTimezone).format('MM/DD/YYYY'),
             time: roundedTime.tz(momentTimezone).format('hh:mm A') + ' ' + moment.tz(momentTimezone).zoneAbbr(),
           };
    }
    if(new Date(cutOffTime) > new Date()){
      return {
        date: formattedDate,
        time: /*formattedTime + ' '*/ '12:00 AM ' + moment.tz(momentTimezone).zoneAbbr(),
      };
    }

    // Return formatted date and time
    return {
      date: formattedDate,
      time: formattedTime + ' ' + moment.tz(momentTimezone).zoneAbbr(),
    };
  }
}