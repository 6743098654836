import { AbstractControl } from '@angular/forms';
import {
  required,
  prop,
  maxLength,
  pattern,
  minLength,
  minNumber,
  disable,
} from '@rxweb/reactive-form-validators';

export class ACHTransactionModel {
  @prop()
  customerId: string;

  @prop()
  bankAccountId: string;

  @prop()
  customerCardId: string;

  @prop()
  @required()
  @minNumber({ value: 0.00 })
  amount: number;

  @prop()
  @required({
    conditionalExpression: function (control: AbstractControl) {
      return this.IsRecurring == false ? false : true;
    },
  })
  scheduledDate: string;

  @prop()
  @required({
    conditionalExpression: function (control: AbstractControl) {
      return this.IsRecurring == false ? false : true;
    },
  })
  frequency: string;

  @prop()
  IsRecurring: boolean = false;
  @prop()
  isSameDay: boolean = false;

  @prop()
  isAgreesToSurcharge: boolean = false;

  @prop()
  @required({
    conditionalExpression: function (control: AbstractControl) {
      return this.IsRecurring == false ? false : true;
    },
  })
  @pattern({ expression: { Number: /^[1-9]*$/ }, message: 'Invalid Installments*' })
  installments: number;

  @prop()
  totalAmount: string;

  @prop()
  entryCode: string;

  @required()
  entryType: string;

  @prop()
  memoText: string;

  @prop()
  externalId: string;

  @prop()
  merchantId: string;
}
