<div
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutAlign="start stretch"
  style="margin-left: 1em; margin-bottom: 2em"
>
  <div fxLayout="column" fxFlex="33%" *ngIf="isAdminDataAvailable">
    <app-dashboard-pie-chart
      [doughnutChartData]="doughnutChartAdminData"
      [doughnutChartType]="doughnutChartType"
      [doughnutChartOptions]="doughnutChartReturnRateOptions"
      [period]="period"
      [name]="'Admin'"
    >
    </app-dashboard-pie-chart>
    <div class="label-style">Admin</div>
  </div>
  <div fxLayout="column" fxFlex="33%" *ngIf="isHostileDataAvailable">
    <app-dashboard-pie-chart
      [doughnutChartData]="doughnutChartHostileData"
      [doughnutChartType]="doughnutChartType"
      [doughnutChartOptions]="doughnutChartReturnRateOptions"
      [period]="period"
    >
    </app-dashboard-pie-chart>
    <div class="label-style">Hostile</div>
  </div>
  <div fxLayout="column" fxFlex="33%" *ngIf="isNonHostileDataAvailable">
    <app-dashboard-pie-chart
      [doughnutChartData]="doughnutChartNonHostileData"
      [doughnutChartType]="doughnutChartType"
      [doughnutChartOptions]="doughnutChartReturnRateOptions"
      [period]="period"
    >
    </app-dashboard-pie-chart>
    <div class="label-style">NSF</div>
  </div>
</div>

<app-data-table
  [columns]="returnRateColumns"
  [data]="returnRateGridData"
  [isLoading]="isLoading"
  [isLoadingPaging]="isLoadingPaging"
  [page]="page"
  [sortBy]="sortBy"
  [noRecordMessage]="noRecordMessage"
  [showMoreMenu]="true"
  [allColumns]="totalAllColumns"
  [selection]="selection"
  [actionRefresh]="actionRefresh"
  (actionRefreshEvent)="onRefresh($event)"
  (pageChangedEvent)="onPageChanged($event)"
  (rowClickedEvent)="onRowClick($event)"
  (sortChangedEvent)="onSortChanged($event)"
  (onFilterEvent)="onFilter($event)"
  [isFilter]="false"
  [isExport]="false"
  [isAddBtn]="false"
  [isCreditDebitSummary]="false"
  [isTotalTransactionLbl]="true"
  [isSearch]="false"
  [isTableHeading]="true"
  [isTableHeadingText]="tableLabel"
  [showPaginator]="false"
>
</app-data-table>
