import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, Observable, of } from 'rxjs';
import { CustomerService } from 'src/app/blockModules/services/customers.service';
import { CreditDebitTransactionService } from 'src/app/featureModules/services/creditDebit-transaction.service';
import { ConfirmationDialogComponent } from 'src/app/sharedModules/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { AddCustomerModel } from 'src/app/sharedModules/models/add-customer.model';
import { CreditDebitTransactionsFilterModel } from 'src/app/sharedModules/models/creditdebit-transaction-filter.model';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import {
  DBFilterOptions,
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { CommonService } from 'src/app/sharedModules/services/common/common.service';
import { getSafeIsoDateString } from 'src/app/sharedModules/utils/dates';

const REFRESH_PAGING_TIMEOUT_MS = 350;
export type ListFilterObj = { [key: string]: string | number | boolean };
export type ListFilter = string | ListFilterObj;

@Component({
  selector: 'app-customer-creditdebit-transaction',
  templateUrl: './customer-creditdebit-transaction.component.html',
  styleUrls: ['./customer-creditdebit-transaction.component.scss'],
})
export class CustomerCreditdebitTransactionComponent implements OnInit,OnDestroy {
  customerId;
  isLoading = false;
  filter: ListFilter = '';
  customerModel: AddCustomerModel;
  customerTransactionsData;

  private timeout: number;

  columns: TableColumn[] = [
    { displayName: 'TRACKING ID', name: 'trackingId', width: '12%' },
    { displayName: 'INVOICE NO', name: 'invoiceNumber', width: '13%' }, 
    { displayName: 'CONVEN. FEE', name: 'surchargeAmount',width: '15%',className:'mL' },   
    { displayName: 'AMOUNT', name: 'amount', width: '10%' },
    
    { displayName: 'TOTAL', name: 'totalAmount', width: '9%' },
    { displayName: 'CREATED', name: 'created', width: '10%' },
    { displayName: 'EFFECTIVE', name: 'effective', width: '10%' },
    { displayName: 'STATUS', name: 'cardStatus', width: '10%' },
    { displayName: 'RECURRING', name: 'hasRecurring', width: '8%',className:'centerAlign' },
    { displayName: 'ACTION', name: 'cardsAction', width: '8%' ,className:'centerAlign'},
  ];

  recurringColumns: TableColumn[] = [
    { displayName: 'TRACKING ID', name: 'trackingId', width: '15%' },
    { displayName: 'EFFECTIVE', name: 'effective', width: '10%' },
    { displayName: 'FREQUENCY', name: 'payFrequency', width: '10%' },      
    { displayName: 'INSTALLMENTS', name: 'installments', width: '12%' },
    { displayName: 'PENDING INSTALLMENTS', name: 'pendingInstallments', width: '13%' },    
    { displayName: 'RECURRING STATUS', name: 'recurringStatus', width: '10%' },
    { displayName: 'TOTAL AMOUNT', name: 'totalAmount', width: '12%' },
    { displayName: 'STATUS', name: 'cardStatus', width: '10%' },
    { displayName: 'ACTION', name: 'cardsAction', width: '8%' ,className:'centerAlign'},
  ];

  allColumns: TableColumn[];

  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };

  sortBy: SortChanged = {
    active: 'effective',
    direction: 'asc',
  };

  @Input()
  listRefreshEvent = new EventEmitter();

  selection = new SelectionModel<TableColumn>(true, []);

  @Input()
  filterEvent = new EventEmitter();

  @Input()
  noRecordMessage: string;

  @Input()
  tabIndex: number;

  loadMessage: string = '';
  isLoadingPaging = false;
  countFilter: ListFilter;
  gotDataResponse = false;
  actionRefresh = false;
  maxLength: string;
  gridData;
  totalTransactions: number = 0;
  totalAmount: number = 0;
  successfulCount: number = 0;
  pendingCount: number = 0;
  failedCount: number = 0;
  cancelledCount: number = 0;
  transactionFilterFormGroup: FormGroup;
  showRecurring;
  boolean = false;
  recurringGridData = [];
  advanceFilters: string = '';
  advanceFiltersList:any;
  advanceFilterCount:number;

  statusList = [
    { key: 'All', value: 'All' },
    { key: 'Pending', value: 'Pending' },
    { key: 'Successful', value: 'Successful' },
    { key: 'Failed', value: 'Failed' },
    { key: 'Cancelled', value: 'Cancelled' }
  ];

  @ViewChild('drawer') drawer: MatDrawer;

  @Input()
  showTransctionHeader: boolean = true;

  isCustomerDataLoaded = false;
  isCustomerStatsLoaded = false;
  isOtherDataLoaded = false;
  isAccess = false;
  userRole :any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _toastrService: ToastrService,
    private _creditDebitTransaction: CreditDebitTransactionService,
    private _customerService: CustomerService,
    private formBuilder: RxFormBuilder,
    private dialog: MatDialog,
    private _commonService: CommonService
  ) {
    this.allColumns = this.columns;
    
    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userProfile['role'];

    const navigation = this.router.getCurrentNavigation();
    this.tabIndex = navigation?.extras?.state
      ? navigation.extras.state['tabIndex']
      : this.tabIndex;

    this.filter = this._commonService.getListDataFromLocalStorage(
      'cardsCustomerGridFilter',
      'cards',
      ''
    );

    this.page = {
      pageIndex: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'cardsCustomerGridPageIndex',
          'cards',
          0
        )
      ),
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'cardsCustomerGridPageSize',
          'cards',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'cardsCustomerGridSortActive',
        'cards',
        'effective'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'cardsCustomerGridSortDirection',
        'cards',
        'asc'
      ),
    };
    this.advanceFilters = this._commonService.getListDataFromLocalStorage(
      'cardsCustomerAdvanceFilter',
      'cards',
      ''
    );
    this.advanceFiltersList = this.advanceFilters.split('|');
    this.advanceFilterCount = this.advanceFiltersList.length - 1;

    let filterFormData = JSON.parse(
      this._commonService.getListDataFromLocalStorage(
        'cardsCustomerAdvanceFilterForm',
        'cards',
        JSON.stringify(new CreditDebitTransactionsFilterModel())
      )
    );

    let filterModel = new CreditDebitTransactionsFilterModel();
    Object.assign(filterModel, filterFormData);
    this.transactionFilterFormGroup = this.formBuilder.formGroup(filterModel);
  }

  ngOnInit(): void {
    this.customerId = this.activatedRoute.snapshot.paramMap.get('customerId');
    if (this.customerId) {
      this.isCustomerDataLoaded = false;
      this.isCustomerStatsLoaded = false;
      this.isOtherDataLoaded = false;
      this.isLoading = true;

      this.getCustomerById();
      this.getCustomerCardTransactionStats();
      this.loadData(
        this.customerId,
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.showRecurring,
        this.advanceFilters
      );
    }
  }

  getCustomerCardTransactionStats() {
    this._creditDebitTransaction
      .GetCustomerCardTransactionStats(this.customerId)
      .subscribe(
        (data) => {
          if (data.data != null) {
            this.customerTransactionsData = data.data;
            this.totalTransactions =
              this.customerTransactionsData.totalTransactions;
            this.totalAmount = this.customerTransactionsData.totalAmount;
            this.successfulCount =
              this.customerTransactionsData.successfulCount;
            this.pendingCount = this.customerTransactionsData.pendingCount;
            this.failedCount = this.customerTransactionsData.failedCount;
            this.cancelledCount = this.customerTransactionsData.cancelledCount;
          }

          this.isCustomerStatsLoaded = true;
          if (this.isCustomerDataLoaded && this.isOtherDataLoaded)
            this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }

  onRefresh(id: string) {}

  public onFilter(event: ListFilter = '') {
    if (this.filter !== event) {
      this.page.pageIndex = 0;

      this.filter = event;
      if (typeof this.filter === 'string' && this.filter) {
        this.filter = this.filter.replace("'", "''");
      }

      this.isLoading = true;
      this.loadData(
        this.customerId,
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.showRecurring,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        this.filter.toString(),
        'cardsCustomerGridFilter',
        'cards'
      );
    }
  }

  loadData(
    customerId,
    filter: ListFilter,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number,
    showRecurring: boolean,
    advanceFilters: string
  ) {
    this.isLoading = true;
    this._creditDebitTransaction
      .GetCustomerCardTransactions(
        customerId,
        filter?.toString(),
        sortBy,
        sortDirection,
        page,
        pageSize,
        this.showRecurring,
        this.advanceFilters
      )
      .subscribe(
        (data) => {
          this.isLoading = false;
          this.isAccess = true;
          this.isOtherDataLoaded = true;
          if (this.isCustomerDataLoaded && this.isCustomerStatsLoaded)
            this.isLoading = false;

          this.drawer?.close();
          if (data.data != null) {
            if (this.showRecurring) {
              this.recurringGridData = data.data.transactionDetails.map((transaction) => ({
                ...transaction,
                cardStatus: transaction.status,
            }));
            } else {
              this.gridData = data.data.transactionDetails.map((transaction) => ({
                ...transaction,
                cardStatus: transaction.status,
            }));
            }
            this.page.length = data.data.totalRecords;
          } else {
            this.gridData = [];
          }
        },
        (error) => {
          this.isLoading = false;
          if (error.status == 403) {
            this.isAccess = false;
          } else {
            this._toastrService.error(
              'Something went wrong, Please contact administrator!'
            );
          }
        }
      );
  }

  getCustomerById() {
    this._customerService.getCustomerById(this.customerId).subscribe(
      (data) => {
        if (data.data != null) {
          this.customerModel = data.data;
        }

        this.isCustomerDataLoaded = true;
        if (this.isCustomerStatsLoaded && this.isOtherDataLoaded)
          this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  getNameInitials(firstName: string, lastName: string) {
    if (firstName && lastName) {
      return (firstName.charAt(0) + lastName.charAt(0)).trim().toUpperCase();
    } else if (firstName) {
      return (firstName.charAt(0) + firstName.charAt(1)).trim().toUpperCase();
    }
    return null;
  }

  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize
    ) {
      this.page = event;

      this.isLoading = true;
      this.loadData(
        this.customerId,
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.showRecurring,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        event.pageSize.toString(),
        'cardsCustomerGridPageSize',
        'cards'
      );
      this._commonService.setListDataToLocalStorage(
        event.pageIndex.toString(),
        'cardsCustomerGridPageIndex',
        'cards'
      );
    }
  }

  onSortChanged(event: SortChanged) {
    const column1: TableColumn = this.columns[event.active];
    var column = this.columns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;
      this.sortByMapping();

      this.isLoading = true;
      this.loadData(
        this.customerId,
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.showRecurring,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        event.active,
        'cardsCustomerGridSortActive',
        'cards'
      );
      this._commonService.setListDataToLocalStorage(
        event.direction,
        'cardsCustomerGridSortDirection',
        'cards'
      );
    }
  }

  sortByMapping() {
    switch (this.sortBy.active) {
      case 'trackingId':
        this.sortBy.active = 'transactionId';
        break;
      case 'cardStatus':
        this.sortBy.active = 'status';
        break;
    }
  }

  protected refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.countFilter = filter;
    this.page.length = 0;
    this.timeout = window.setTimeout(() => {
      this._refreshPaging(filter, count, options);
    }, REFRESH_PAGING_TIMEOUT_MS);
  }

  private _refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    this.isLoadingPaging = true;
    const countOptions = Object.assign({}, options);
    delete countOptions.$orderBy;
    delete countOptions.$skip;
    delete countOptions.$top;
    delete countOptions.$expand;

    countOptions.$count = true;
    if (this.maxLength) {
      countOptions.$top = Number(this.maxLength);
    }

    count(countOptions)
      .pipe(
        map((response: number) => {
          if (filter === this.countFilter) {
            this.page.length = response > 0 ? response : 0;
            this.isLoading = this.gotDataResponse == false ? true : false;
          }
        }),
        catchError((err) => {
          this.onError(err.message);
          this.isLoading = false;
          return of([]);
        })
      )
      .subscribe(() => {
        this.isLoadingPaging = false;
      });
  }

  onError(message: any) {
    throw new Error('Method not implemented.');
  }

  public onRowClick(customer: any) {}

  onAddClick() {
    this.router.navigate(['transactions/add-creditdebit', this.customerId], {
      state: {
        url:
          '/transactions/customer-creditdebit/' +
          this.customerId +
          '/' +
          this.tabIndex,
        tabIndex: this.tabIndex,
      },
    });
  }

  navigateToCreditDebitTransactionsPage() {
    this.router.navigate(['transactions'], {
      state: {
        tabIndex: this.tabIndex,
      },
    });
  }

  navigationToPage() {}

  public restForm() {
    if (this.transactionFilterFormGroup) {
      this.transactionFilterFormGroup.reset();
      this.transactionFilterFormGroup = this.formBuilder.formGroup(
        new CreditDebitTransactionsFilterModel()
      );
    }
    this.advanceFilters = '';
  }

  public applyAdvanceFilter() {
    let data = this.transactionFilterFormGroup.value;
    data.scheduledStartDate?.setHours(12, 0, 0);
    data.scheduledEndDate?.setHours(12, 0, 0);
    data.processedStartDate?.setHours(12, 0, 0);
    data.processedEndDate?.setHours(12, 0, 0);

    this.page = {
      pageIndex: 0,
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'cardsCustomerGridPageSize',
          'cards',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'cardsCustomerGridSortActive',
        'cards',
        'effective'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'cardsCustomerGridSortDirection',
        'cards',
        'asc'
      ),
    };
    this.advanceFilters = '';
    if (data.status && data.status != 'All') {
      this.advanceFilters += 'status:' + data.status + '|';
    }
    if (data.scheduledStartDate && data.scheduledEndDate) {
      this.advanceFilters +=
        'sdate:' +
        getSafeIsoDateString(data.scheduledStartDate) +
        'to' +
        getSafeIsoDateString(data.scheduledEndDate) +
        '|';
    }
    if (data.processedStartDate && data.processedEndDate) {
      this.advanceFilters +=
        'pdate:' +
        getSafeIsoDateString(data.processedStartDate) +
        'to' +
        getSafeIsoDateString(data.processedEndDate) +
        '|';
    }
    if (data.amountRangeFrom && data.amountRangeTo) {
      if (parseFloat(data.amountRangeFrom) > parseFloat(data.amountRangeTo)) {
        this._toastrService.warning(
          'Amount From cannot be greater than Amount To'
        );
        return;
      } else {
        this.advanceFilters +=
          'amount:' + data.amountRangeFrom + 'to' + data.amountRangeTo + '|'; ;
      }
    }
    this._commonService.setListDataToLocalStorage(
      this.advanceFilters.toString(),
      'cardsCustomerAdvanceFilter',
      'cards'      
    );
    this.advanceFiltersList = this.advanceFilters.split('|');
    this.advanceFilterCount = this.advanceFiltersList.length - 1;

    this._commonService.setListDataToLocalStorage(
      JSON.stringify(this.transactionFilterFormGroup.value),
      'cardsCustomerAdvanceFilterForm',
      'cards'
    );

    this.isLoading = true;
    this.loadData(
      this.customerId,
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.showRecurring,
      this.advanceFilters
    );
  }

  recurringTransaction(event) {
    this.showRecurring = event.checked;
    this.isLoading = true;

    this.page = {
      pageIndex: 0,
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'cardsCustomerGridPageSize',
          'cards',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'cardsCustomerGridSortActive',
        'cards',
        'effective'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'cardsCustomerGridSortDirection',
        'cards',
        'asc'
      ),
    };
    this.loadData(
      this.customerId,
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.showRecurring,
      this.advanceFilters
    );
  }

  deleteCardRecurringTransaction(transactionId: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '155px',
      data: {
        delete: true,
        buttonName: 'YES',
        successMessage:
          'Are you sure you want to cancel this recurring transaction?',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.isLoading = true;
        this._creditDebitTransaction
          .DeleteInstallmentTransaction(false, transactionId)
          .subscribe((data) => {
            if (data.code == 200) {
              this._toastrService.success(
                'Recurring Transaction cancelled Successfully!'
              );

              this.loadData(
                this.customerId,
                this.filter,
                this.sortBy.active,
                this.sortBy.direction,
                this.page.pageIndex,
                this.page.pageSize,
                this.showRecurring,
                this.advanceFilters
              );
            }
          });
      }
    });
  }
  ngOnDestroy() {
    this.filterEvent.unsubscribe();
    this.listRefreshEvent.unsubscribe();
    clearTimeout(this.timeout);
    if(!(this.router.url.includes('transactions')     
    || this.router.url.includes('add-creditdebit'))){
    localStorage.removeItem('cards');
    }
  }
}
