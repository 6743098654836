import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ReportsService } from 'src/app/featureModules/services/reports.service';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import { GenerateReportModel } from 'src/app/sharedModules/models/generate-report.model';
import {
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { CommonService } from 'src/app/sharedModules/services/common/common.service';

@Component({
  selector: 'app-generate-report-grid',
  templateUrl: './generate-report-grid.component.html',
  styleUrls: ['./generate-report-grid.component.scss'],
})
export class GenerateReportGridComponent implements OnInit, OnDestroy {
  isAchLoading: boolean;
  isCardsLoading: boolean;
  isEcheckLoading: boolean;

  loadMessage: string = 'Please wait..';
  achData: any;
  cardsData: any;
  eCheckData: any;

  achPage: PageChanged = {
    pageIndex: 0,
    pageSize: 5,
    length: 100,
    previousPageIndex: 0,
  };
  achSortBy: SortChanged = {
    active: 'effectiveDate',
    direction: 'desc',
  };

  cardsPage: PageChanged = {
    pageIndex: 0,
    pageSize: 5,
    length: 100,
    previousPageIndex: 0,
  };
  cardsSortBy: SortChanged = {
    active: 'effective',
    direction: 'desc',
  };

  eCheckPage: PageChanged = {
    pageIndex: 0,
    pageSize: 5,
    length: 100,
    previousPageIndex: 0,
  };
  eCheckSortBy: SortChanged = {
    active: 'effective',
    direction: 'desc',
  };

  achColumns: TableColumn[] = [
    { displayName: '', name: 'icon', width: '5%' },
    { displayName: 'NAME', name: 'name', width: '14%' },
    { displayName: 'TRACKING ID', name: 'trackingId', width: '12%' },
    { displayName: 'CODE', name: 'entryCode', width: '6%' },
    { displayName: 'CREDIT/DEBIT', name: 'paymentType', width: '11%' },    
    { displayName: 'BANK ACCT.', name: 'BankAccountNumber', width: '12%' },
    { displayName: 'CREATED', name: 'createdDate', width: '10%' },
    { displayName: 'EFFECTIVE', name: 'effectiveDate', width: '9%' },
    { displayName: 'CONVEN. FEE', name: 'surchargeAmount', width: '8%' },
    { displayName: 'AMOUNT', name: 'Amount', width: '8%' },
    { displayName: 'STATUS', name: 'achStatus', width: '10%' },
    { displayName: 'NOC', name: 'nocCode', width: '5%' }      
  ];

  creditDebiColumns: TableColumn[] = [
    { displayName: '', name: 'icon', width: '5%' },
    { displayName: 'NAME', name: 'name', width: '19%' },
    { displayName: 'TRACKING ID', name: 'transactionId', width: '13%' },
    { displayName: 'INVOICE NO', name: 'invoiceNumber', width: '13%' },    
    { displayName: 'AMOUNT', name: 'amount', width: '10%' },
    { displayName: 'CONVEN. FEE', name: 'surchargeAmount', width: '12%' },
    { displayName: 'TOTAL', name: 'totalAmount', width: '13%' },
    { displayName: 'CREATED', name: 'created', width: '13%' },
    { displayName: 'EFFECTIVE', name: 'effective', width: '13%' },        
    { displayName: 'STATUS', name: 'cardStatus', width: '13%' },
  ];

  eCheckColumns: TableColumn[] = [
    { displayName: '', name: 'icon', width: '5%' },
    { displayName: 'NAME', name: 'name', width: '15%' },
    { displayName: 'CHECK NUMBER', name: 'checkNumber', width: '12%' },
    { displayName: 'CREATED', name: 'created', width: '12%' },
    { displayName: 'EFFECTIVE', name: 'effective', width: '12%' },
    { displayName: 'AMOUNT', name: 'amount', width: '10%' },
    { displayName: 'NOTES', name: 'memoText', width: '10%' },
    { displayName: 'STATUS', name: 'echeckStatus', width: '12%' },
  ];

  achAllColumns: TableColumn[];
  cardsAllColumns: TableColumn[];
  eCheckAllColumns: TableColumn[];
  generateReportAchModel: GenerateReportModel;
  generateReportCardsModel: GenerateReportModel;
  generateReportEcheckModel: GenerateReportModel;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _toastrService: ToastrService,
    public _reportService: ReportsService,
    private _commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => { });
    if (this._reportService.generateReportModel) {
      this.GenerateReport();
    } else {
      this.router.navigate(['reports/generate-report']);
    }
  }

  GenerateReport() {
    switch (this._reportService.generateReportModel.typeOfService) {
      case 'All':
        this.achData = [];
        this.cardsData = [];
        this.eCheckData = [];
        this.generateReportAchModel = JSON.parse(JSON.stringify(this._reportService.generateReportModel));
        this.generateReportCardsModel = JSON.parse(JSON.stringify(this._reportService.generateReportModel));
        this.generateReportEcheckModel = JSON.parse(JSON.stringify(this._reportService.generateReportModel));
        this.getAchSortAndPagination();
        this.GenerateReportForAch();
        this.getCardsSortAndPagination();
        this.GenerateReportForCreditDebit();
        this.getEcheckSortAndPagination();
        this.GenerateReportForEcheck();
        break;
      case 'ACH':
        this.achData = [];
        this.generateReportAchModel = JSON.parse(JSON.stringify(this._reportService.generateReportModel));
        this.getAchSortAndPagination();
        this.GenerateReportForAch();
        break;
      case 'CREDITDEBIT':
        this.cardsData = [];
        this.generateReportCardsModel = JSON.parse(JSON.stringify(this._reportService.generateReportModel));
        this.getCardsSortAndPagination();
        this.GenerateReportForCreditDebit();
        break;
      case 'INSTANTFUND':
        //todo
        //this.data = [];
        break;
      case 'ECHECK':
        this.eCheckData = [];
        this.generateReportEcheckModel = JSON.parse(JSON.stringify(this._reportService.generateReportModel));
        this.getEcheckSortAndPagination();
        this.GenerateReportForEcheck();
        break;
    }
  }

  getAchSortAndPagination() {
    this.achPage = {
      pageIndex: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'generateAchReportGridPageIndex',
          'reports',
          0
        )
      ),
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'generateAchReportGridPageSize',
          'reports',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.achSortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'generateAchReportGridSortActive',
        'reports',
        this.achSortBy.active
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'generateAchReportGridSortDirection',
        'reports',
        this.achSortBy.direction
      ),
    };
    if (this.generateReportAchModel.sortBy)
      this.achSortBy.active = this.generateReportAchModel.sortBy;
    if (this.generateReportAchModel.sortDirection == 'asc')
      this.achSortBy.direction = 'asc';
    if (this.generateReportAchModel.sortDirection == 'desc')
      this.achSortBy.direction = 'desc';
  }

  getCardsSortAndPagination() {
    this.cardsPage = {
      pageIndex: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'generateCardsReportGridPageIndex',
          'reports',
          0
        )
      ),
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'generateCardsReportGridPageSize',
          'reports',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };
    this.cardsSortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'generateCardsReportGridSortActive',
        'reports',
        this.cardsSortBy.active
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'generateCardsReportGridSortDirection',
        'reports',
        this.cardsSortBy.direction
      ),
    };
    if (this.generateReportCardsModel.sortBy)
      this.cardsSortBy.active = this.generateReportCardsModel.sortBy;
    if (this.generateReportCardsModel.sortDirection == 'asc')
      this.cardsSortBy.direction = 'asc';
    if (this.generateReportCardsModel.sortDirection == 'desc')
      this.cardsSortBy.direction = 'desc';
  }

  getEcheckSortAndPagination() {
    this.eCheckPage = {
      pageIndex: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'generateEcheckReportGridPageIndex',
          'reports',
          0
        )
      ),
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'generateEcheckReportGridPageSize',
          'reports',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };
    this.eCheckSortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'generateEcheckReportGridSortActive',
        'reports',
        this.eCheckSortBy.active
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'generateEcheckReportGridSortDirection',
        'reports',
        this.eCheckSortBy.direction
      ),
    };

    if (this.generateReportEcheckModel.sortBy)
      this.eCheckSortBy.active = this.generateReportEcheckModel.sortBy;
    if (this.generateReportEcheckModel.sortDirection == 'asc')
      this.eCheckSortBy.direction = 'asc';
    if (this.generateReportEcheckModel.sortDirection == 'desc')
      this.eCheckSortBy.direction = 'desc';
  }

  GenerateReportForAch() {
    this.achAllColumns = this.achColumns;
    this.isAchLoading = true;
    this.generateReportAchModel.sortBy = this.achSortBy.active;
    this.generateReportAchModel.sortDirection = this.achSortBy.direction;
    this.generateReportAchModel.page = this.achPage.pageIndex;
    this.generateReportAchModel.pageSize = this.achPage.pageSize;

    this._reportService.GenerateReportForAch(this.generateReportAchModel).subscribe(
      (data) => {
        this.isAchLoading = false;
        if (data.data != null) {
          this.achData = data.data.achTransactions;
          if (this.achData && this.achData.length) {
            this.achData.forEach((element) => {
              element.AccountType = element.accountType.toUpperCase();
              element.BankAccountNumber = element.accountNumber.replace(
                /.(?=.{4,}$)/g,
                '*'
              );
              element.ReturnMessage = element.returnMessage;
              element.ReturnStatusCode = element.returnStatusCode;              
              element.achStatus = element.status;
            });
            this.achData.map((item) => {
              item.Amount = item.amount;
              item.PaymentType = item.paymentType;
            });
          }
          this.achPage.length = data.data.totalRecords;
        } else {
          this.achData = [];
        }
      },
      (error) => {
        this.isAchLoading = false;
        this.loadMessage = '';
        this._toastrService.error(
          'Something went wrong, Please contact administrator!'
        );
      }
    );
  }

  GenerateReportForCreditDebit() {
    this.cardsAllColumns = this.creditDebiColumns;
    this.isCardsLoading = true;
    this.generateReportCardsModel.sortBy = this.cardsSortBy.active;
    this.generateReportCardsModel.sortDirection = this.cardsSortBy.direction;
    this.generateReportCardsModel.page = this.cardsPage.pageIndex;
    this.generateReportCardsModel.pageSize = this.cardsPage.pageSize;

    this._reportService.GenerateReportForCreditDebit(this.generateReportCardsModel).subscribe(
      (data) => {
        this.isCardsLoading = false;
        if (data.data != null) {
          this.cardsData = data.data.cardTransactions.map((transaction) => ({
            ...transaction,
            cardStatus: transaction.status,
        }));
          this.cardsPage.length = data.data.totalRecords;
        } else {
          this.cardsData = [];
        }
      },
      (error) => {
        this.isCardsLoading = false;
        this.loadMessage = '';
        this._toastrService.error(
          'Something went wrong, Please contact administrator!'
        );
      }
    );
  }

  GenerateReportForEcheck() {
    this.eCheckAllColumns = this.eCheckColumns;
    this.isEcheckLoading = true;
    this.generateReportEcheckModel.sortBy = this.eCheckSortBy.active;
    this.generateReportEcheckModel.sortDirection = this.eCheckSortBy.direction;
    this.generateReportEcheckModel.page = this.eCheckPage.pageIndex;
    this.generateReportEcheckModel.pageSize = this.eCheckPage.pageSize;

    this._reportService.GenerateReportForEcheck(this.generateReportEcheckModel).subscribe(
      (data) => {
        this.isEcheckLoading = false;
        if (data.data != null) {
          this.eCheckData = data.data.eCheckTransactions;
          this.eCheckData.forEach((element) => {
            element.firstName = element.payeeFirstName;
            element.lastName = element.payeeLastName;
            element.email = element.payeeEmail;
            element.echeckStatus = element.status;
          });
          this.eCheckPage.length = data.data.totalRecords;
        } else {
          this.eCheckData = [];
        }
      },
      (error) => {
        this.isEcheckLoading = false;
        this.loadMessage = '';
        this._toastrService.error(
          'Something went wrong, Please contact administrator!'
        );
      }
    );
  }

  navigateToBackPage() {
    this.router.navigate(['reports']);
  }

  manageFilters() {
    this.router.navigate(['reports/generate-report']);
  }

  // Server side Pagination
  onAchPageChanged(event: PageChanged) {
    if (
      this.achPage.pageIndex !== event.pageIndex ||
      this.achPage.pageSize !== event.pageSize
    ) {
      this.achPage = event;

      this._commonService.setListDataToLocalStorage(
        event.pageSize.toString(),
        'generateAchReportGridPageSize',
        'reports'
      );
      this._commonService.setListDataToLocalStorage(
        event.pageIndex.toString(),
        'generateAchReportGridPageIndex',
        'reports'
      );
    }
    this.GenerateReportForAch();
  }
  // Server side Pagination
  onCardsPageChanged(event: PageChanged) {
    if (
      this.cardsPage.pageIndex !== event.pageIndex ||
      this.cardsPage.pageSize !== event.pageSize
    ) {
      this.cardsPage = event;

      this._commonService.setListDataToLocalStorage(
        event.pageSize.toString(),
        'generateCardsReportGridPageSize',
        'reports'
      );
      this._commonService.setListDataToLocalStorage(
        event.pageIndex.toString(),
        'generateCardsReportGridPageIndex',
        'reports'
      );
    }
    this.GenerateReportForCreditDebit();
  }
  // Server side Pagination
  onEcheckPageChanged(event: PageChanged) {
    if (
      this.eCheckPage.pageIndex !== event.pageIndex ||
      this.eCheckPage.pageSize !== event.pageSize
    ) {
      this.eCheckPage = event;

      this._commonService.setListDataToLocalStorage(
        event.pageSize.toString(),
        'generateEcheckReportGridPageSize',
        'reports'
      );
      this._commonService.setListDataToLocalStorage(
        event.pageIndex.toString(),
        'generateEcheckReportGridPageIndex',
        'reports'
      );
    }
    this.GenerateReportForEcheck();
  }
  // Server side Sorting
  onAchSortChanged(event: SortChanged) {
    var column = this.achColumns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.achSortBy.active !== event.active ||
      this.achSortBy.direction !== event.direction
    ) {
      this.achSortBy = event;
      this.sortByMappingAch();

      this._commonService.setListDataToLocalStorage(
        event.active,
        'generateAchReportGridSortActive',
        'reports'
      );
      this._commonService.setListDataToLocalStorage(
        event.direction,
        'generateAchReportGridSortDirection',
        'reports'
      );
    }
    this.GenerateReportForAch();
  }

  sortByMappingAch() {
    switch (this.achSortBy.active) {
      case 'name':
        this.achSortBy.active = 'firstName';
        break;
      case 'achStatus':
          this.achSortBy.active = 'status';
          break;
      case '':
        this.achSortBy = {
          active: this.generateReportAchModel.sortData.active,
          direction: this.generateReportAchModel.sortData.direction,
        };
        break;
    }
  }

  // Server side Sorting
  onCardsSortChanged(event: SortChanged) {
    var column = this.creditDebiColumns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.cardsSortBy.active !== event.active ||
      this.cardsSortBy.direction !== event.direction
    ) {
      this.cardsSortBy = event;
      this.sortByMappingCards();

      this._commonService.setListDataToLocalStorage(
        event.active,
        'generateCardsReportGridSortActive',
        'reports'
      );
      this._commonService.setListDataToLocalStorage(
        event.direction,
        'generateCardsReportGridSortDirection',
        'reports'
      );
    }
    this.GenerateReportForCreditDebit();
  }
  sortByMappingCards() {
    switch (this.cardsSortBy.active) {
      case 'name':
        this.cardsSortBy.active = 'firstName';
        break;
      case 'cardStatus':
          this.cardsSortBy.active = 'status';
          break;
      case '':
        this.cardsSortBy = {
          active: this.generateReportCardsModel.sortData.active,
          direction: this.generateReportCardsModel.sortData.direction,
        };
        break;
    }
  }

  // Server side Sorting
  onEcheckSortChanged(event: SortChanged) {
    var column = this.eCheckColumns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.eCheckSortBy.active !== event.active ||
      this.eCheckSortBy.direction !== event.direction
    ) {
      this.eCheckSortBy = event;
      this.sortByMappingeCheck();

      this._commonService.setListDataToLocalStorage(
        event.active,
        'generateEcheckReportGridSortActive',
        'reports'
      );
      this._commonService.setListDataToLocalStorage(
        event.direction,
        'generateEcheckReportGridSortDirection',
        'reports'
      );
    }
    this.GenerateReportForEcheck();
  }

  sortByMappingeCheck() {
    switch (this.eCheckSortBy.active) {
      case 'name':
        this.eCheckSortBy.active = 'PayeeFirstName';
        break;
      case 'echeckStatus':
          this.eCheckSortBy.active = 'status';
          break;
      case '':
        this.eCheckSortBy = {
          active: this.generateReportEcheckModel.sortData.active,
          direction: this.generateReportEcheckModel.sortData.direction,
        };
        break;
    }
  }

  downloadReport() {
    switch (this._reportService.generateReportModel.typeOfService) {
      case 'All':
        this._reportService.DownloadReportForAllTransaction(this.generateReportAchModel);
        break;
      case 'ACH':
        this._reportService.DownloadReportForAch(this.generateReportAchModel);
        break;
      case 'CREDITDEBIT':
        this._reportService.DownloadReportForCreditDebit(this.generateReportCardsModel);
        break;
      case 'INSTANTFUND':
        //todo
        break;
      case 'ECHECK':
        this._reportService.DownloadReportForEcheck(this.generateReportEcheckModel);
        break;
    }
  }

  titleCase(str: string) {
    if (str) {
      return str
        .toLowerCase()
        .split(' ')
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');
    }
  }

  ngOnDestroy() {
    if (!this.router.url.includes('reports'))
      localStorage.removeItem('reports');
  }
}
