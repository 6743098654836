<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>

<div class="backHeader">
  <mat-label>
    <button mat-icon-button (click)="navigateToTransactionsPage()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <span *ngIf="!existingCustomer"> ACH Transactions Summary </span>
    <span *ngIf="existingCustomer"> ACH Transactions Details </span>
  </mat-label>
</div>
<br />

<div class="add-page-header-spacing page-header-text">
  Create ACH Transaction
</div>
<div class="transaction-instruction">
  All individual & company related transactions can be created from this page
</div>
<br />

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
  <div fxLayout="column" fxFlex="40%">
    <img src="assets/grid-icons/addTransaction.png" />
  </div>
  <div fxLayout="column" fxFlex="60%" style="background: #ffffff">
    <mat-label class="create-now">Create Now</mat-label>
    <mat-stepper
      orientation="vertical"
      [linear]="true"
      #stepper
      style="background: #ffffff"
    >
      <ng-template matStepperIcon="edit">
        <mat-icon>check</mat-icon>
      </ng-template>
      <mat-step [stepControl]="firstFormGroup" *ngIf="!existingCustomer">
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel class="stepper-header-style">
            <span class="stepper-header-label" style="font-size: 13px">
              CUSTOMER
              <span *ngIf="userName != ''"> : {{ userName }} </span>
            </span>
          </ng-template>
          <br />
          <mat-label class="inner-stepper-text">Select Customer</mat-label>
          <app-search-customer
            (onCustomerSelectionEvent)="onCustomerSelection($event)"
          ></app-search-customer>
          <div style="padding-top: 10px">
            <button
              mat-stroked-button
              matStepperNext
              class="page-header-button-style"
            >
              CONTINUE
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="firstFormGroup" *ngIf="existingCustomer">
        <form [formGroup]="firstFormGroup">
          <ng-template
            matStepLabel
            class="stepper-header-style"
            style="font-size: 13px"
          >
            <span class="stepper-header-label">
              CUSTOMER
              <span *ngIf="userName != ''"> : {{ userName }} </span>
            </span>
          </ng-template>
        </form>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
          <ng-template
            matStepLabel
            class="stepper-header-style"
            style="font-size: 13px"
          >
            <span class="stepper-header-label">
              BANK ACCOUNT
              <span *ngIf="selectedBankName"> : {{ selectedBankName }} </span>
            </span>
          </ng-template>

          <div *ngIf="cardList.length == 0">
            <div class="chooseCardLabel" style="font-size: 12px">
              <span
                >No bank accounts available. Please add new bank account for
                this customer using 'Customers' page to create transaction</span
              >
            </div>
          </div>

          <div *ngIf="cardList.length > 0">
            <div
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutWrap
              fxLayoutGap="16px"
              fxLayoutAlign="start stretch"
            >
              <div fxLayout="column" fxFlex="100%">
                <div class="chooseCardLabel" style="font-size: 12px">
                  Choose Bank Account
                </div>
              </div>
            </div>
            <!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px"
                            fxLayoutAlign="start stretch">
                            <div fxLayout="column" fxFlex="100%">
                                <mat-form-field appearance="outline">
                                    <mat-label>Base Commerce</mat-label>
                                    <mat-select name="processor">
                                        <mat-option *ngFor="let processor of processors" [value]="processor.value">
                                            {{processor.viewValue}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div> -->

            <div *ngFor="let card of cardList">
              <div class="bank-account">
                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutWrap
                  fxLayoutGap="16px"
                  fxLayoutAlign="start stretch"
                  style="padding: 20px"
                >
                  <div fxLayout="column" fxFlex="5%">
                    <div style="margin-top: 8px">
                      <mat-radio-group
                        (change)="onCardSelected($event, card)"
                        fxFlex="100%"
                        class="form-control"
                        name="bankSelection"
                        style="font-size: 12px"
                      >
                        <mat-radio-button
                          style="width: 100px; font-size: 15px"
                          [value]="true"
                        >
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                  <div fxLayout="column" fxFlex="80%">
                    <div fxLayout="row">{{ card.bankName }}</div>
                    <div fxLayout="row">
                      {{ bankEncreption(card.bankAccountNumber) }}
                    </div>
                  </div>
                  <div fxLayout="column" fxFlex="15%">
                    <div style="margin-top: 8px">
                      <img
                        width="28"
                        height="28"
                        src="{{ getBankImage(card.bankAccountNumber) }}"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>

          <div style="padding-top: 10px">
            <button
              mat-stroked-button
              matStepperNext
              class="page-header-button-style"
              [disabled]= "cardList.length == 0"
            >
              CONTINUE
            </button>
            &nbsp;&nbsp;
            <button mat-button matStepperPrevious class="white-button-style">
              BACK
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="thirdFormGroup">
        <form [formGroup]="thirdFormGroup">
          <ng-template
            matStepLabel
            class="stepper-header-style"
            style="font-size: 13px"
          >
            <span class="stepper-header-label"> AMOUNT </span>
          </ng-template>
          <!-- <div *ngIf="isRecurring" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch" class="form-group" >
                        <div fxFlex="100%">
                            <mat-radio-group fxFlex="30%" >
                                <mat-radio-button  *ngFor="let radio of listRadio"  [value]="radio.value" [checked]="radio.checked" >
                                  {{ radio.name }} 
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div> -->
          <br />
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutAlign="start stretch"
            class="form-group"
          >
            <div fxFlex="100%" style="margin-bottom: 5px">
              <span class="amountDetails" style="font-size: 12px"
                >How much amount you want to transfer?</span
              >
            </div>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutAlign="start stretch"
            class="form-group"
          >
            <div
              fxLayout="row"
              fxFlex="100%"
              fxLayout.xs="column"
              fxLayoutWrap
              fxLayoutAlign="start stretch"
              class="form-group"
            >
              <div fxFlex="45%">
                <mat-form-field appearance="outline">
                  <mat-label>Entry Type</mat-label>
                  <mat-select name="entryType" formControlName="entryType">
                    <mat-option
                      *ngFor="let entry of entryTypeList"
                      [value]="entry.value"
                    >
                      {{ entry.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div fxFlex="5%"></div>
              <div fxFlex="45%">
                <mat-form-field appearance="outline">
                  <mat-label>Entry Code</mat-label>
                  <input
                    type="text"
                    matInput
                    formControlName="entryCode"
                    readonly
                  />
                </mat-form-field>
              </div>
            </div>
          </div>

          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutAlign="start stretch"
            class="form-group"
          >
            <div
              fxLayout="row"
              fxFlex="100%"
              fxLayout.xs="column"
              fxLayoutWrap
              fxLayoutAlign="start stretch"
              class="form-group"
            >
              <div fxFlex="45%">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Amount</mat-label>
                  <input
                    type="text"
                    (focusout)="calculateTotalAmount()"
                    matInput
                    appTwoDigitDecimalNumber
                    formControlName="amount"
                    required
                  />
                  <span matPrefix class="material-icons">attach_money</span>
                  <mat-error>
                    {{ thirdFormGroup.controls["amount"]["_errorMessage"] }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxFlex="5%"></div>
              <div fxFlex="30%">
                <mat-form-field appearance="outline">
                  <mat-label>Transaction date</mat-label>
                  <input
                    matInput
                    [matDatepicker]="datepicker"
                    formControlName="scheduledDate"
                    (dateChange)="changeDate()"
                    [matDatepickerFilter]="holidayFilter"
                    readonly
                    [min]="minDate"
                    [max]="maxDate"
                    [disabled]="!isTransactionDate"
                  />
                  <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="datepicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker
                    #datepicker
                    [startAt]="defaultScheduledDate"
                    [dateClass]="dateClass"
                  >
                    <mat-datepicker-actions>
                      <button mat-button matDatepickerCancel>Cancel</button>
                      <button
                        mat-raised-button
                        color="primary"
                        matDatepickerApply
                      >
                        Apply
                      </button>
                    </mat-datepicker-actions>
                  </mat-datepicker>
                </mat-form-field>
              </div>
              <div fxFlex="2%"></div>
              <div fxFlex="10%" style="margin-top: 30px">
                <section class="example-section">
                  <mat-checkbox
                    formControlName="isSameDay"
                    (change)="sameDaycheck($event)"
                    [disabled]="!isSameDayAllowed"
                  >
                    Same Day
                  </mat-checkbox>
                </section>
              </div>
            </div>
          </div>

          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutAlign="start stretch"
            class="form-group"
          >
            <div
              fxLayout="row"
              fxFlex="100%"
              fxLayout.xs="column"
              fxLayoutWrap
              fxLayoutAlign="start stretch"
              class="form-group"
            >
              <div fxFlex="95%">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Description</mat-label>
                  <textarea
                    matInput
                    autocomplete="off"
                    formControlName="memoText"
                  ></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutAlign="start stretch"
            class="form-group"
          >
            <div fxFlex="80%" style="margin-bottom: 5px">
              <section class="example-section">
                <mat-checkbox
                  class="example-margin"
                  (change)="recurringChecked($event)"
                  formControlName="IsRecurring"
                  [disabled]="disableRecurring"
                >
                  Recurring Transaction
                </mat-checkbox>
              </section>
            </div>
          </div>

          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutAlign="start stretch"
            class="form-group"
          >
            <div fxFlex="100%" style="padding-top: 15px">
              <label class="notice-text" *ngIf="isValidTime">
                (Same Day Processing is allowed till {{ endWindowTime }} Hours)
              </label>
              <label class="notice-text" *ngIf="!isValidTime">
                (Same day is not allowed at this time.)
                <span *ngIf="!isValidHoliday">
                  Window time is {{ startWindowTime }} to {{ endWindowTime }}
                </span>
              </label>
            </div>
          </div>

          <div
            *ngIf="isRecurring"
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutAlign="start stretch"
            class="form-group"
          >
            <div fxFlex="45%">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Installments</mat-label>
                <input
                  type="number"
                  (focusout)="calculateTotalAmount()"
                  matInput
                  formControlName="installments"
                  required
                />
                <mat-error>
                  {{ thirdFormGroup.controls["installments"]["_errorMessage"] }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="5%"></div>
            <div fxFlex="45%">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Total Amount</mat-label>
                <input
                  type="text"
                  matInput
                  readonly
                  formControlName="totalAmount"
                  required
                />
              </mat-form-field>
            </div>
          </div>
          <div
            fxLayout="row"
            *ngIf="isRecurring"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutAlign="start stretch"
            class="form-group"
          >
            <div fxFlex="45%">
              <mat-form-field appearance="outline">
                <mat-label>Frequency</mat-label>
                <mat-select name="frequency" formControlName="frequency">
                  <mat-option
                    *ngFor="let frequency of frequencies"
                    [value]="frequency.value"                    
                  >
                    {{ frequency.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div
            fxLayout="row"
            *ngIf="isRecurring && this.thirdFormGroup.controls['scheduledDate'].value"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutAlign="start stretch"
            class="form-group"
          >
            <div fxFlex="90%" class="notice-text-red">
              <label >
                Note: For recurring payments, the initial payment made today is not counted towards the total number of scheduled recurring payments that begin on {{this.thirdFormGroup.controls['scheduledDate'].value | date:'MM/dd/yyyy'}} 
              </label> 
            </div>
          </div>
          <br /><br />
        </form>
        <div style="padding-top: 10px">
          <button
            mat-stroked-button
            matStepperNext
            class="page-header-button-style"
            (click)="openDialog()"
          >
            SUBMIT
          </button>
          &nbsp;&nbsp;
          <button mat-button matStepperPrevious class="white-button-style">
            BACK
          </button>
        </div>
      </mat-step>
    </mat-stepper>
  </div>

  <ng-template #callAPIDialog>
    <form #userForm="ngForm" (ngSubmit)="addAchTransaction()">
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="start stretch"
        class="dialog-confirm"
      >
        <div fxLayout="column" fxFlex="94%">
          <h2 style="padding-bottom: 2%">Confirmation</h2>
        </div>
        <div
          fxLayout="column"
          fxFlex="6%"
          fxLayoutAlign="end stretch"
          style="margin-bottom: 25px"
        >
          <mat-icon [mat-dialog-close]>close</mat-icon>
        </div>
      </div>

      <div class="dialog-confirm">
        <h2>Are you sure you want to create this?</h2>
        <div *ngIf="convenienceFeeAmount > 0">
          <span class="popup-text-style"> Clicking on Yes button will apply convenience fees of <b>${{ convenienceFeeAmount | number :"1.2-2" }}</b> on current total amount. </span>          <br /> <br />
        </div>
        <span class="popup-text-style"> It will take 3-5 business days for credits/debits to reflect in recipient's account. </span>
        <br />
        <br />
        <span class="popup-text-style"> We will notify you when there is an update. </span>
      </div>
      <mat-dialog-actions align="end">
        <button mat-button matDialogClose="no" class="white-button-style">
          Cancel
        </button>
        <button mat-button class="page-header-button-style">Yes</button>
      </mat-dialog-actions>
    </form>
  </ng-template>

  <ng-template #callReturnedRate>
    <form #userForm="ngForm">
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="start stretch"
        class="dialog-confirm"
      >
        <div fxLayout="column" fxFlex="90%">
          <h2 style="padding-bottom: 10%">Confirmation</h2>
        </div>
        <div
          fxLayout="column"
          fxFlex="10%"
          fxLayoutAlign="end stretch"
          style="margin-bottom: 25px; padding-bottom: 7%"
        >
          <mat-icon [mat-dialog-close]>close</mat-icon>
        </div>
      </div>

      <div class="dialog-confirm-ReturnedRate">
        <span> {{ messageReturnedRate }}</span>
        <br />
        <span> We will redirect to create ECheck Transaction In dispute. </span>
        <br />
        <span> We will notify you when there is an update. </span>
      </div>
      <mat-dialog-actions align="end">
        <button
          mat-button
          matDialogClose="no"
          class="white-button-style"
          (click)="cancelReturnedRate()"
        >
          CANCEL
        </button>
        <button
          mat-button
          class="page-header-button-style"
          (click)="returnedRate()"
        >
          YES
        </button>
      </mat-dialog-actions>
    </form>
  </ng-template>
</div>
